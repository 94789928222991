<template>
  <div>
    <validation-provider
      :name="label"
      :vid="vid"
      :rules="rules"
      v-if="is_loaded && hasFields"
      slim
    >
      <giga-button
        :label="$t('curriculum.actions.transports.add_transports')"
        @click="add"
      />

      <div class="" header-class="d-flex w-100 justify-content-between">
        <div
          class="card border-radius-base mt-2"
          v-for="(s, index) in computedValue"
          :key="index"
        >
          <div class="card-body">
            <div class="flex-row">
              <span
                class="title text-lg font-weight-semibold flex justify-content-between"
              >
                <span class="text-md font-weight-semibold">
                  {{ getDomainLabel("way_transport", s.way_transport) }}
                </span>
                <span>
                  <PencilAltIcon
                    v-on:click="edit(s, index)"
                    class="text-primary cursor-pointer"
                  />
                  <TrashIcon
                    v-on:click="remove(s, index)"
                    class="text-primary cursor-pointer"
                  />
                </span>
              </span>
            </div>
            <span class="text-md font-weight-medium" v-if="s.type_transport">{{
              $t("curriculum.transports.type_transport.label")
            }}</span>
            <p class="text-sm" v-if="s.type_transport">
              {{ getDomainLabel("transports", s.type_transport) }}
            </p>
            <span class="text-md font-weight-medium" v-if="s.path_transport">{{
              $t("curriculum.transports.path_transport.label")
            }}</span>
            <p class="text-sm" v-if="s.path_transport">
              {{ s.path_transport }}
            </p>
            <span class="text-md font-weight-medium" v-if="s.value_transport">{{
              $t("curriculum.transports.value_transport.label")
            }}</span>
            <p class="text-sm" v-if="s.value_transport">
              {{ toCurrency(s.value_transport) }}
            </p>
          </div>
        </div>
      </div>

      <b-modal
        ref="modal"
        id="benefits-transports-modal"
        size="lg"
        centered
        :title="$t('curriculum.actions.transports.add_transports')"
        @ok="append($event)"
        @cancel="clear"
        scrollable
      >
        <template #modal-footer="{ ok, cancel }">
          <b-button
            class="border"
            variant="outline-primary"
            pill
            size="sm"
            @click="cancel()"
          >
            {{ $t("common.cancel") }}
          </b-button>
          <b-button variant="primary" pill size="sm" @click="ok()">
            {{ $t("common.save") }}
          </b-button>
        </template>

        <validation-observer ref="entityRules" tag="div" class="">
          <form-set-generic
            section="transports"
            v-model="new_entity"
            :fields="internalFields"
            autocomplete="off"
            :field-props="{
              untracked: true,
            }"
          />
        </validation-observer>
      </b-modal>
    </validation-provider>
  </div>
</template>

<script>
import * as _ from "lodash-es";

import FormFieldSet from "../mixins/Set";
import FormFieldBase from "../mixins/Base";
import { isoToLocal } from "@/plugins/i18n";
import * as FormComponents from "@components/forms";
import GigaButton from "@components/buttons/GigaButton.vue";
import FormSetGeneric from "@components/forms/set/Generic.vue";
import { PencilAltIcon, TrashIcon } from "@vue-hero-icons/outline";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  mixins: [FormFieldBase, FormFieldSet],
  components: {
    TrashIcon,
    GigaButton,
    PencilAltIcon,
    FormSetGeneric,
    ...FormComponents,
    ValidationObserver,
    ValidationProvider,
  },
  setup() {
    return {
      formatDate: isoToLocal,
    };
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    toCurrency(data) {

      // Create currency formatter
      let formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      });

      // Convert payload into string
      if (typeof data !== "string") {
        data = String(data);
      }

      // Override comma with dot and convert to number
      data = Number(data.replace(",", "."));

      // Check if the conversion was successful
      if (isNaN(data)) {
        return data;
      }

      // Convert into currency
      return formatter.format(data);
    },
  },
};
</script>
