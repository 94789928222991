<template>
  <validation-provider :name="label" :vid="vid" :rules="rules" v-if="is_loaded && hasFields" slim>
    <div class="" header-class="d-flex w-100 justify-content-between">

      <!-- Card info -->
      <div class="card border-radius-base mt-2" v-for="(s, index) in computedValue" :key="index">
        <div class="card-body">
          <div class="flex-row">
            <span class="title text-lg font-weight-semibold flex justify-content-between">
              <p style="max-width: 85%" v-if="s.name">
                {{ s.name }}
              </p>
              <span>
                <PencilAltIcon v-on:click="edit(s, index)" class="text-primary cursor-pointer"/>
                <TrashIcon v-on:click="remove(s, index)" class="text-primary cursor-pointer"/>
              </span>
            </span>
          </div>
          <span class="text-sm font-weight-medium" v-if="s.institution">
            {{ $t("curriculum.education.training.institution.label") }}
          </span>
          <p class="text-sm" v-if="s.institution">
            {{ s.institution }}
          </p>
          <span class="text-sm font-weight-medium" v-if="s.description">
            {{ $t("curriculum.education.training.description.label") }}
          </span>
          <p class="text-sm" v-if="s.description">
            {{ s.description }}
          </p>
          <span class="text-sm font-weight-medium" v-if="s.status">
          {{ $t("curriculum.education.training.status.label") }}
          </span>
          <p class="text-sm" v-if="s.status">
            {{ getDomainLabel("education_situation", s.status) }}
          </p>
          <span class="text-sm font-weight-medium" v-if="s.start_date">
            {{ $t("common.start_end") }}
          </span>
          <p class="font-weight-medium" v-if="s.start_date">
            {{ formatDate(s.start_date) }}
            <span v-if="['c', 'p'].indexOf(s.status) >= 0 && s.finish_date">
              {{ " - " + formatDate(s.finish_date) }}
            </span>
            <span v-else-if="s.status == 's'">
              {{ " - " + $t("common.present") }}
            </span>
          </p>
        </div>
      </div>

      <!-- Add new -->
      <giga-button :label="$t('curriculum.actions.education.add_training')" @click="add"/>
    </div>

    <b-modal
      ref="modal"
      id="education-academic-modal"
      size="lg"
      centered
      :title="$t('curriculum.actions.education.add_training')"
      @ok="append($event)"
      @cancel="clear"
      scrollable
    >
      <template #modal-footer="{ ok, cancel }">
        <b-button class="border" variant="outline-primary" pill size="sm" @click="cancel()">
          {{ $t("common.cancel") }}
        </b-button>
        <b-button variant="primary" pill size="sm" @click="ok()">
          {{ $t("common.save") }}
        </b-button>
      </template>

      <validation-observer ref="entityRules" tag="div" class="">
        <form-set-generic
          section="education.training"
          v-model="new_entity"
          :fields="internalFields"
          :field-props="{
            untracked: true,
          }"
          autocomplete="off"
        >
          <template #finish_date="{ model, field, componentName, testId, component }">
            <span>
              <component
                v-if="model['status'] == 'c'"
                :is="component.is"
                :name="componentName"
                :test-id="testId"
                v-model="model[field]"
                v-bind="component"
                autocomplete="off"
              />
            </span>
          </template>
        </form-set-generic>
      </validation-observer>
    </b-modal>
  </validation-provider>
</template>

<script>
import * as _ from "lodash-es";
import FormFieldSet from "../mixins/Set";
import FormFieldBase from "../mixins/Base";
import { isoToLocal } from "@/plugins/i18n";
import * as FormComponents from "@components/forms";
import { useCurriculum } from "@state/user/curriculum";
import GigaButton from "@components/buttons/GigaButton.vue";
import FormSetGeneric from "@components/forms/set/Generic.vue";
import { PencilAltIcon, TrashIcon } from "@vue-hero-icons/outline";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  mixins: [FormFieldBase, FormFieldSet],
  components: {
    TrashIcon,
    GigaButton,
    PencilAltIcon,
    FormSetGeneric,
    ...FormComponents,
    ValidationObserver,
    ValidationProvider,
  },
  setup() {
    const curriculum = useCurriculum();

    return {
      curriculum,
      formatDate: isoToLocal,
    };
  },
  data() {
    return {
      trainingOptions: [],
    };
  },
  created() {
    this.trainingOptions = this.domain.additional_training;
  },
  methods: {
    searchTraining: _.debounce(function (data) {
      if (data.length > 2) {
        this.domain.getTraining({ param: data }).then((response) => {
          // this.trainingOptions = _.union(this.trainingOptions, response.data)
          this.trainingOptions = [
            ...this.domain.additional_training,
            response.data,
          ];
        });
      }
    }, 100),
    addTrainingActivity(name, refName) {
      this.domain
        .create_record("additional_training", { label: name })
        .then((record) => {
          this.trainingOptions.push(record);
        });
    },
  },
};
</script>
