<template>
    <validation-provider
        ref="validator"
        #default="{ errors, required }"
        :name="internalLabel"
        :vid="internalVid"
        :rules="rules"
        slim
    >
        <b-form-group
            :id="fieldsetId"
            :name="internalVid"
            :description="internalDescription"
            :label="internalLabel"
            :label-for="inputId"
            :state="errors.length == 0"
        >
            <template #label>
                <slot name="label" :inputId="inputId" :label="internalLabel">
                    <label :id="fieldsetId + '__BV_label_'" :for="inputId" class="d-block" v-if="internalLabel">
                        {{ internalLabel }}
                        <span v-if="required" class="text-danger">*</span>
                    </label>
                </slot>
            </template>

            <b-input-group :prepend="countryPrefix">
                <b-form-input
                    ref="input"
                    :id="inputId"
                    :class="{'is-invalid' : errors.length > 0}"
                    :label="internalLabel"
                    v-model="internalValue"
                    @change="dispatchEvent('change', internalValue)"
                    :placeholder="internalPlaceholder"
                    :data-cy="internalTestId"
                    v-bind="$attrs"
                    v-mask="Array.isArray(mask) ? [...mask] : mask"
                    autocomplete="off"
                />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>

    </validation-provider>
</template>

<script>
import * as _ from 'lodash-es'

import { ValidationProvider } from 'vee-validate'
import companyMixin from '@state/company'
import FormFieldBase from '../mixins/Base'
import masks from '@lang/config/masks';

/**
 * Componente que encapsula o b-form-input + diretiva v-mask,  para aplicar máscaras e validações baseados em tipos de telefone por país.
 */
export default {
    name: 'form-field-phone',
    mixins : [
        companyMixin,
        FormFieldBase
    ],
    components: {
        ValidationProvider
    },
    props : {
        /**
         * Propriedade que define o tipo de telefone a ser capturado.
         * @values cellphone, homephone, both
         */
        phoneType : {
            type: String,
            required: false,
            default : 'cellphone',
            validator: (value) => ['cellphone', 'homephone', 'both'].indexOf(value) != -1
        }
    },
    data() {
        return {
            masks,
            defaultMasks : masks.BR,
            countries: [
                {name: 'BR', code: '55'},
                {name: 'US', code: '1'},
                {name: 'ES', code: '34'},
            ]
        };
    },
    computed : {
        mask() {
            // GET THE MASK BASED ON LOCALE AND phoneType. OR BASES ON COUNTRY CODE IF IT'S
            const countryMasks = this.masks[this.company.currentCountry];

            if (this.phoneType == "both") {
                return _.get(this.masks, this.company.currentCountry + '.cellphone');
            }

            if (_.has(this.masks, this.company.currentCountry + '.' + this.phoneType)) {
                return _.get(this.masks, this.company.currentCountry + '.' + this.phoneType);
            }

            return _.get(this.defaultMasks, this.phoneType, "(##) # #### ####");
        },

        countryPrefix(){
            let prefix = '55'
            const found = this.countries.find(c => c.name === this.company.currentCountry)
            if(found !== null) {
                prefix = found.code
            }

            return `+${prefix}`
        }
    }
}
</script>
<!--
<docs>
```vue
  <template>
    <div class="container">
        <form-field-phone phone-type="cellphone" v-model="cellphoneValue" name="field-name" />
        <form-field-phone phone-type="cellphone" v-model="homephoneValue" name="field-name" />
    </div>
  </template>

  <script>
    export default {
      data() {
        return {
            cellphoneValue : '',
            homephoneValue : ''
        };
      }
    }
  </script>
```
</docs>
-->
