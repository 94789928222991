// en, de, fr, br
import validationMessages from "vee-validate/dist/locale/en";

export default {
    //"en-US": {
    validations: {
        messages: {
            ...validationMessages.messages,
            "email-or-cpf": "The field {_field_} is not a valid email or CPF",
            cpf: "The field {_field_} has an invalid format",
            cnpj: "The field {_field_} has an invalid format",
            pis: "The field {_field_} has an invalid format",
            "zip-code": "The field {_field_} has an invalid format",
            "full-name": "The field {_field_} is invalid",
            date: "The field {_field_} is an invalid date",
            "before-date": "The date entered is greater than the end date",
            "after-date": "The date entered is shorter than the start date",
            "minority-date": "The minimum register age is 14 years old",
            required_unless: "The field {_field_} is required",
            must_exists_if: "You need to select at least one option",
            cns: "The field has an invalid value",
            "password-username-checker": "The provided password cannot be used.",
            "password-weakness-checker": "The provided password is too weak.",
        },
    },
    //Find a better place to allocate this ↓↓↓
    image: {
        change_image: "Change image",
        send_image: "Send image"
    },
    files: {
        remove: {
            done: "File removed successfully",
            fail: "Your file could not be deleted. Please try again",
        },
        the_requested_file_could_not_be_found: "The requested file could not be found",
    },
    changeable: {
        "indicator-text":
            "Saved changes | {count} pending changes | {count} pending changes",
    },
    errors: {
        403: "Forbidden",
        404: "Not found",
        "create-password": "Create a password!",
        account_recover: {
            "not-found": "Invalid data, please try again",
        },
        link_account: {
            "already-existent":
                "There is already an account with this document. Log in and link your account in the settings menu.",
        },
        communication: {
            "not-sent":
                "We were unable to register your contact. Please try again",
        },
        title: {
            danger: "Error",
            warning: "Notice",
            info: "Information",
        },
        "default-title": "Error",
        account: {
            error: "Failed to send reset email, please try again.",
            "social-media-account":
                "Your account was created using social media. To recover your account, contact the company responsible.",
            "not-found":
                "Account not found, please check if the CPF was entered correctly.",
            "password-reset-throttled":
                "A reset email was sent a few moments ago, please wait 10 minutes before trying again.",
        },
        registration: {
            message:
                "We were unable to complete your registration. Please try again.",
            message_alt: "Unable to register. Please try again.",
            "account-existent":
                "You already have an account! Log in to continue.",
            cpf_already_registered_in_our_database: "CPF already registered in our database!",
        },
        curriculum: {
            "not-found": "Curriculum not found",
            invalid_zip_format:  "The zip code field has an invalid format",
        },
        vacancy: {
            "not-found": "Vacancy not found",
        },
        enroll: {
            "not-found": "Vacancy not found",
            "already-applied": "You have already applied for this vacancy!",
            forbidden: "You are not allowed to apply for this vacancy",
            "unfilled-fields":
                "There are some required fields for completing the application. Please fill in the fields below to proceed:",
            "does-not-attend":
                "Your curriculum does not meet the minimum requirements below:",
            "maintain-updated":
                "Keep your resume updated for new opportunities.",
            pcd: "PCD",
            education: "Education",
            languages: "Languages",
            computing: "Computing",
            "exclusive-selective-process":
                "This selective process requires exclusivity, and the candidate is participating in another process",
        },
        file: {
            file_upload_failed: "The file upload was not successful!",
        }
    },
    responses: {
        communication: {
            success:
                "We have received your request and will contact you as soon as possible for resolution",
        },
        feedback: {
            success: "Thank you for your help!",
        },
    },
    ocr: {
        error_title: "Attention!",
        error_description:
            "We couldn't identify some information from the document:",
        error_validate: "Please check:",
        error_validate_item_1: "The image of the attached document",
        error_validate_item_2: " The fields above are correctly filled",
        await_title: "Please wait",
        await_description: "We are validating the document information.",
    },
    parsing: {
        send_another_file: "Send another file",
        check_the_info:
            "Check if the fields are filled correctly and complete if necessary",
        ready: "Ready!",
        read_file: "Reading your resume...",
        start_fill: "Start Filling",
        search_file: "Search File",
        send_file:
            "If you prefer, send your resume (PDF or Word) to create your profile instantly",
    },
    common: {
        understood: "Got it",
        wait: "Please wait!",
        saving_info: "Saving information...",
        saving_changes: "Saving changes...",
        dependent: "Dependent",
        attention: "Attention",
        success: "Success",
        error: "Error",
        loading: "Loading",
        send: "Send",
        my_painel: "My Panel",
        complementary_data: "Complementary Data",
        admission_data: "Admission Data",
        apply: "Apply",
        yes: "Yes",
        create_new_section: "Create new section",
        no: "No",
        male: "Masculine",
        female: "Feminine",
        required: "Required field",
        back_to_home: "Back to home",
        edit: "Edit",
        save: "Save",
        cancel: "Cancel",
        delete: "Delete",
        upload_photo: "Upload Photo",
        advance: "Advance",
        "no-results-found": "No result found",
        "search-new-term": "Enter a new search term.",
        see: "See",
        search: "Search",
        or: "or",
        and: "and",
        description: "Description",
        progress: "Progress",
        link_with_sj: "Link with SelectyJobs",
        link_with_facebook: "Link with Facebook",
        link_with_linkedin: "Link with LinkedIn",
        link_with_google: "Link with Google",
        join: "Login",
        join_with_sj: "Login with SelectyJobs",
        join_with_facebook: "Login with Facebook",
        join_with_linkedin: "Login with LinkedIn",
        join_with_google: "Login with Google",
        join_with_ad: "Login with Active Directory",
        please_type_captcha_correctly: "Please complete the captcha correctly.",
        to_continue_auth: "To continue, select one of the options below",
        register: "Register",
        create_account: "Create Account",
        create_profile: "Create Profile",
        profile: "Profile",
        lets_begin: "Let's get started!",
        lets_begin_subtitle: "Fill in basic information below",
        jobs: "Vacancies",
        show: "Show",
        hide: "Hide",
        hello: "Hello",
        complete_curriculum: "Complete my resume",
        follow_process: "Follow the selection process",
        curriculum: "Resume",
        admissional: "Admission",
        logout: "Logout",
        exit_warning: "There are mandatory fields not filled, do you really want to leave?",
        begin: "Start",
        vacancy: "Vacancies",
        messages: "Messages",
        account: "Account",
        email: "Email",
        password: "Password",
        change: "Change",
        close: "Close",
        in: "in",
        click: "Click here",
        to_add: "to add",
        captcha_error: "Please complete the captcha correctly.",
        course_not_found: "Course not found",
        institution_not_found: "Institution not found",
        document_not_found: "Document not found",
        training_not_found: "Training not found",
        occupation_not_found: "Occupation not found",
        please_insert_document: "Please provide your CPF.",
        why_we_need_that: "Why do we need this information?",
        creation_garantee:
            "We want to ensure you have a unique and secure registration. Therefore, the CPF will be your main access key.",
        validate_informations: "Validate information",
        present: "present",
        create_professional_profile: "Register your professional profile now!",
        start_end: "Start/End",
        period: "Period",
        visit: "Visit",
        for_companies: "For companies",
        notification_updated_successfully: "Notification updated successfully",
        failed_to_update_data: "Failed to update data.",
        user_successfully_linked: "User successfully linked!",
        successfully_completed: "Successfully completed",
        updated_successfully: "Updated successfully",
        status_updated: "Status updated",
        status_not_updated: "Status not updated",
        email_updated_successfully: "Email updated successfully",
        password_changed_successfully: "Password changed successfully",
        avatar_successfully_updated: "Avatar successfully updated",
        your_request_was_successfully_completed: "Your request was successfully completed",
        invalid_data: "Invalid data!",
        incorrect_data: "Incorrect data",
        invalid_captcha: "Invalid captcha!",
        successfully_saved: "Saved successfully",
        data_save_error: "Error saving data",
        physical: "Physical",
        digital: "Digital",
        field_mismatch:  "The field does not match the uploaded file",
        application: "Application",
        results: "Result",
        answer: "Answer",
        perform_assessment: "Perform assessment",
        download_file: "Download file",
        accepted: "Accepted",
    },
    redirecting: {
        redirecting: "Redirecting",
        please_wait: "Please wait...",
        access_forbiden: "Access forbidden",
        enroll_portal: "You will be redirected to the portal",
    },
    reset_password: {
        passwords: {
            sent: "E-mail sent successfully.",
            user: "E-mail sent successfully.",
            token: "Invalid token. Please retry the process.",
            reset: "Your password has been successfully changed.",
        },
    },
    password_level: {
        very_week: "Very weak",
        week: "Weak",
        regular: "Regular",
        strong: "Very strong",
    },
    forms: {
        fields: {
            upload: "Attach file",
            remove_file: "Remove file",
            allowed_extensions: "Permitted extensions",
            default_error: "Unable to send the file. Please try again.",
        },
    },
    company: {
        config: {
            logo_position: {
                label: "Logo position",
                placeholder: "Select the logo position",
                description:
                    "Select the best position for your logo in the portal.",
            },
        },
    },
    customize: {
        main_title: "Customize",
        subtitle:
            "Choose the theme color, add content areas, photos, and more.",
        color_theme: "Theme color",
        color_theme_description: "Affects buttons and interactive elements.",
        logo_position: "Logo position",
        logo_position_description:
            "Select the best position for your logo in the portal.",
        sections: "Content areas",
        sections_description:
            "Drag the section to change the display position.",
        type: {
            placeholder: "Select",
        },
        base_theme: {
            label: "Main theme",
            placeholder: "Select the main theme",
            description: "",
        },
        "primary-color": {
            label: "Primary color",
            placeholder: "Select the primary color",
            description: "",
        },
        title: {
            label: "Title",
            placeholder: "Enter the title",
            description: "",
        },
        banner: {
            add_banner: "Add Banner",
            remove_banner: "Remove Banner",
            more_details: "More details",
            redirect_url: {
                label: "Redirect link",
                placeholder: "Enter the redirect link",
                description: "",
            },
            banner_specifications: {
                recommended_dimensions: "Recommended dimensions:",
                maximum_file_size: "Maximum file size:",
                supported_files: "Supported files:",
                dimensions: "1920 x 560px",
                size: "10mb",
                formats: "JPG or PNG"
            },
        },
    },
    passwords: {
        cpf: {
            label: "CPF",
            placeholder: "Enter your CPF",
            description: "",
        },
        cellphone: {
            label: "Cellphone",
            placeholder: "Enter your registered phone number",
            description: "",
        },
        birth_date: {
            label: "Birth date",
            placeholder: "Enter your birth date",
            description: "",
        },
        primary_email: {
            label: "Email",
            placeholder: "Enter your registered email",
            description: "",
        },
    },
    user: {
        login: {
            email: {
                label: "Email",
                placeholder: "Enter your email",
                description: "",
            },
            is_foreigner: {
                label: "I'm a foreigner",
                placeholder: "",
                description: "",
            },
            login: {
                label: "CPF",
                placeholder: "Enter your CPF",
                description: "",
            },
            password: {
                label: "Password",
                placeholder: "Enter your password",
                description: "",
            },
            submit: "Sign in",
            linked: "Login with LinkedIn",
            sj: "Login with SelectyJobs",
            joinMessage: "Sign in to continue",
            joinSjMessage: "Log in to your SelectyJobs account to continue",
            needAccount: "Not registered",
            create_account: "Create an account",
            forgotPassword: "Forgot Password",
            invalidEmailOrPassword: "Invalid email and/or password.",
            forgotUsername: "Forgot your login details?",
        },
        forgot: {
            login: {
                label: "CPF",
                placeholder: "Enter your CPF",
                description: "",
            },
            nextStep: "Next",
            title: "Forgot your password?",
            info: "No problem. We will send you an e-mail with instructions to create a new password.",
            submit: "Create a new password",
            return_to: "Back to login",
            email_sent: "Email sent!",
            invalid_email: "Invalid email!",
            email_instructions:
                "We have sent an email to {email} with instructions to create a new password.",
            email_spam_instructions:
                "If the email takes a while, check your spam folder.",
            no_longer_email_access: "I no longer have access to this email",
            recover_account: "Recover account",
            recover_account_failure: "Failed to recover account.",
            contact_to:
                "Account data validation failed. To recover your account, contact {company_name}.",
            social_media_account:
                "Your account was created using a social media platform. Log in with that social media platform or recover your account below:",
            atention: "Attention!",
        },
        forgotAccount: {
            title: "Forgot your login details?",
            info: "No worries! Let's validate some information to recover your account.",
            next: "Next",
            validate_data: "Validate data",
        },
        confirmAccountData: {
            title: "We found your account!",
            info: "For security reasons, let's confirm some information before recovering your account.",
            cellphone: "Confirm the phone number registered in your account:",
            birth_date: "Confirm your birth date below:",
            email: "Confirm the email registered to your account:",
        },
        reset: {
            title: "Create a new password",
            info: "Almost there! Just enter a new password.",
            submit: "Change my password",
            return_to: "Back to login",
        },
        reseted_password_success: {
            title: "Success!",
            info: "Your password has been successfully changed.",
        },
        reseted_password_error: {
            title: "Oops!",
            info: "There was an error changing your password, please try again.",
        },
        reset_email: {
            title: "Register a new email",
            info: "Almost there! Just register a new email address to reset the password.",
            submit: "Change email",
            return_to: "Back to login",
        },
    },
    curriculum: {
        title: "Curriculum",
        sections: {
            personal: {
                title: "Personal Data",
                address: "Address Information",
                contacts: "Contact Information",
                required_contacts:
                    "Please provide at least one contact phone number.",
                diversity: "Diversity and Inclusion",
                diversity_subtitle:
                    "We value diversity in the workplace, so you are free to provide the information below:",
                extra: "Additional Fields",
                cnh: "National Driver's License",
                login: "Your Login",
                bnt_ready: "All Set",
                confirm: "Confirm",
                extra_by_company: "Additional Fields {company}",
            },
            parsing: {
                title: "Automatic CV Reader",
                btn_upload_curriculum: "Fill for me - Import CV",
            },
            professional: {
                title: "Professional Information",
                experiences: "Your Experiences",
                objectives: "Objectives",
                experience: "Professional Journey",
                add_experience: "Add Your Professional Experiences",
            },
            education: {
                title: "Education",
                academic: "Your Education",
                training: "Additional Training",
                languages: "Languages",
                computing: "Computer Knowledge",
            },
            dependents: {
                title: "Dependents",
            },
            other_information: {
                title: "Other Information",
            },
            additional: {
                title: "Additional Information",
            },
            presentation: {
                title: "Presentation",
            },
        },
        actions: {
            professional: {
                add_experience: "Add Experience",
            },
            education: {
                add_academic: "Add Education",
                add_training: "Add course or training",
                add_language: "Add Language",
            },
            dependents: {
                add_dependent: "Add Dependent",
            },
            transports: {
                add_transports: "Add Transport",
            },
        },
        texts: {
            professional: {
                activities: "Activities",
            },
            education: {
                academic: {
                    time: {
                        morning: "Morning",
                        afternoon: "Afternoon",
                        night: "Night",
                    },
                    period: {
                        period: "{n}º Period",
                    },
                    situation: {
                        studying: "Studying",
                        completed: "Completed",
                        stopped: "Interrupted",
                    },
                },
                none: "None",
                basic: "Basic",
                intermediate: "Intermediate",
                advanced: "Advanced",
                fluent: "Fluent",
            },
            dependents: {
                tokens: {
                    a: "Valid Child",
                    b: "Child Unable to Work",
                    c: "Spouse",
                    d: "Father",
                    e: "Mother",
                    f: "Father-in-law",
                    g: "Others",
                    h: "Grandparent",
                    i: "Partner",
                    j: "Stepson",
                    k: "Deleted",
                    l: "Ex-Spouse",
                    m: "Valid Sibling",
                    n: "Sibling Unable to Work",
                    o: "Ex-Partner",
                    p: "Ex-Father-in-law",
                    q: "Grandchild",
                    r: "Ex-Stepson",
                },
            },
            personal: {
                pcd: {
                    not_informed: "Not Informed",
                    none: "None",
                    physical: "Physical",
                    visual: "Visual",
                    auditive: "Auditory",
                    intelectual: "Intellectual",
                    psicosocial: "Psychosocial",
                    autism: "Autism",
                    multiple: "Multiple",
                    rehabilitated: "Rehabilitated",
                },
            },
        },
        sensitive: {
            login_username: {
                label: "Email or CPF",
                placeholder: "",
                description: "",
            },
            new_primary_email: {
                label: "New Email",
                placeholder: "Enter a new email",
                description: "",
            },
            new_primary_email_confirmation: {
                label: "Email Confirmation",
                placeholder: "Confirm your new email",
                description: "",
            },
            password: {
                label: "Password",
                placeholder: "Enter your password",
                description: "",
            },
            password_confirmation: {
                label: "Password Confirmation",
                placeholder: "Confirm the entered password",
                description: "",
            },
            new_password: {
                label: "New Password",
                placeholder: "Create a new password",
                description: "",
            },
            new_password_confirmation: {
                label: "New Password Confirmation",
                placeholder: "Confirm your new password",
                description: "",
            },
        },
        personal: {
            is_foreigner: {
                label: "I am a Foreigner",
                placeholder: "",
                description: "",
            },
            origin_country: {
                label: "Country of Origin",
                placeholder: "",
                description: "",
            },
            document_type_id: {
                label: "Identification Document",
                placeholder: "",
                description: "",
            },
            document_number: {
                label: "Document Number",
                placeholder: "",
                description: "",
            },
            primary_email: {
                label: "Email",
                placeholder: "Enter your email",
                description: "",
            },
            primary_email_confirmation: {
                label: "Email Confirmation",
                placeholder: "Confirm your email",
                description: "",
            },
            name: {
                label: "Full Name",
                placeholder: "Enter your full name",
                description: "",
            },
            assumed_name: {
                label: "Social Name",
                placeholder: "",
                description: "",
            },
            photo3x4_file_id: {
                label: "3 x 4 Photo",
                placeholder: "",
                description: "",
            },
            cpf: {
                label: "CPF",
                placeholder: "Enter your CPF",
                description: "",
            },
            gender: {
                label: "Gender",
                placeholder: "Sex",
                description: "This information is mandatory for E-social.",
            },
            birth_date: {
                label: "Date of Birth",
                placeholder: "dd/mm/yyyy",
                description: "",
            },
            cellphone: {
                label: "Cell Phone (with WhatsApp)",
                placeholder: "(00) 00000-0000",
                description: "",
            },
            home_phone: {
                label: "Home Phone",
                placeholder: "Enter your home phone number",
                description: "",
            },
            special_needs: {
                label: "Do you qualify as a PWD?",
                placeholder: "Select",
                description: "",
            },
            special_needs_details: {
                label: "Specify",
                placeholder: "Select",
                description: "PWD Description",
            },
            special_needs_file: {
                label: "Attach PWD Report",
                description: "Attach Report/Rehabilitation Certificate",
                placeholder: "Attach Report",
            },
            rg: {
                label: "RG",
                placeholder: "RG",
                description: "",
            },
            state_rg: {
                label: "RG Issuing State",
                placeholder: "RG Issuing State",
                description: "",
            },
            organ_rg: {
                label: "RG Issuing Body",
                placeholder: "RG Issuing Body",
                description: "",
            },
            civil_status: {
                label: "Civil Status",
                placeholder: "Civil Status",
                description: "This information is mandatory for E-social.",
            },
            cnh_type: {
                label: "CNH Type",
                placeholder: "CNH Type",
                description: "",
            },
            children: {
                label: "Number of Children",
                placeholder: "Number of children",
                description: "",
            },
            children_info: {
                label: "Extra information about your children",
                placeholder: "",
                description: "",
            },
            cellphone: {
                label: "Phone (with WhatsApp)",
                placeholder: "(00) 00000-0000",
                description: "",
            },
            home_phone: {
                label: "Home Phone",
                placeholder: "Enter your home phone number",
                description: "",
            },
            business_phone: {
                label: "Business Phone",
                placeholder: "Business Phone",
                description: "",
            },
            message_with: {
                label: "Contact for Message",
                placeholder: "Contact for Message",
                description: "",
            },
            secondary_email: {
                label: "Secondary Email",
                placeholder: "Secondary Email",
                description: "",
            },
            site: {
                label: "Website",
                placeholder: "Website",
                description: "",
            },
            avatar: {
                label: "Avatar",
                placeholder: "",
                description: "",
            },
            cv_external: {
                label: "Attach Curriculum",
                placeholder: "",
                description: "",
            },
            cpf_file_id: {
                label: "Attach CPF",
                placeholder: "",
                description: "",
            },
            city_rg: {
                label: "RG Issuing City",
                placeholder: "",
                description: "",
            },
            date_of_issue_rg: {
                label: "RG Issue Date",
                placeholder: "",
                description: "",
            },
            rg_file_id: {
                label: "Attach RG (front)",
                placeholder: "",
                description: "",
            },
            rg_file2_id: {
                label: "Attach RG (back)",
                placeholder: "",
                description: "",
            },
            birth_file_id: {
                label: "Attach Birth Certificate",
                placeholder: "",
                description: "",
            },
            nationality: {
                label: "Nationality",
                placeholder: "",
                description: "",
            },
            born_state: {
                label: "State of Birth",
                placeholder: "",
                description: "",
            },
            born_city: {
                label: "City of Birth",
                placeholder: "",
                description: "",
            },
            mother_name: {
                label: "Mother's Name",
                placeholder: "",
                description: "",
            },
            mother_cpf: {
                label: "Mother's CPF",
                placeholder: "",
                description: "",
            },
            father_name: {
                label: "Father's Name",
                placeholder: "",
                description: "",
            },
            father_cpf: {
                label: "Father's CPF",
                placeholder: "",
                description: "",
            },
            racial: {
                label: "Race / Color",
                placeholder: "",
                description: "",
            },
            national_health_service_number: {
                label: "National Health Service Number",
                placeholder: "",
                description: "",
            },
            national_health_service_number_file_id: {
                label: "Attach SUS Card",
                placeholder: "",
                description: "",
            },
            professional_number: {
                label: "Professional Registration Number (e.g., CRM, CRP)",
                placeholder: "",
                description: "",
            },
            professional_file_id: {
                label: "Attach Professional Registration",
                placeholder: "",
                description: "",
            },
            mother_name_not_declared_in_the_document:
                "Mother's name not declared in the document",
            father_name_not_declared_in_the_document:
                "Father's name not declared in the document",
        },
        address: {
            country: {
                label: "Country",
                placeholder: "Select the Country",
                description: "",
            },
            zip_code: {
                label: "Zip Code",
                placeholder: "Enter your Zip Code",
                description: "",
            },
            street_type: {
                label: "Type of Address",
                placeholder: "Type of Address",
                description: "",
            },
            street: {
                label: "Street",
                placeholder: "Enter your street",
                description: "",
            },
            number: {
                label: "Number",
                placeholder: "Enter the Number",
                description: "",
            },
            complement: {
                label: "Complement",
                placeholder: "Enter the Complement",
                description: "",
            },
            district: {
                label: "Neighborhood",
                placeholder: "Enter the Neighborhood",
                description: "",
            },
            state: {
                label: "State",
                placeholder: "Select a State",
                description: "",
            },
            city: {
                label: "City",
                placeholder: "Select a City",
                description: "",
            },
            address_file_id: {
                label: "Proof of Residence",
                placeholder: "",
                description: "",
            },
        },
        diversity: {
            racial: {
                label: "Racial",
                description: "This information is mandatory for E-social.",
                placeholder: "",
            },
            assumed_name: {
                label: "Social Name",
                description: "Name by which you identify yourself and are socially recognized.",
                placeholder: "",
            },
            gender_identity: {
                label: "Gender Identity",
                description: "",
                placeholder: "",
            },
            gender_orientation: {
                label: "Sexual Orientation",
                description: "",
                placeholder: "",
            },
        },
        professional: {
            intended_occupation: {
                label: "Desired Positions",
                placeholder: "",
                description: "(max 3 positions)",
            },
            interest_levels: {
                label: "Level of Interest",
                placeholder: "",
                description: "(max 3 levels)",
            },
            interest_areas: {
                label: "Area of Interest",
                placeholder: "",
                description: "(max 3 areas)",
            },
            salary_intended: {
                label: "Salary Expectation",
                placeholder: "Enter your Salary Expectation",
                description: "",
            },
            available_trip: {
                label: "Available to Travel?",
                placeholder: "",
                description: "",
            },
            has_professional_history: {
                label: "Do you have Professional Experience?",
                placeholder: "",
                description: "",
            },
            has_not_professional_history: {
                label: "I have no Professional Experience",
                placeholder: "",
                description: "",
            },
            experiences: {
                company_id: {
                    label: "Company",
                    placeholder: "Enter the Company Name",
                    description: "",
                },
                company_name: {
                    label: "Company",
                    placeholder: "Enter the Company Name",
                    description: "",
                },
                occupation_id: {
                    label: "Position",
                    placeholder: "Enter the Position",
                    description: "",
                },
                start: {
                    label: "Start Date",
                    placeholder: "dd/mm/yyyy",
                    description: "",
                },
                finish: {
                    label: "End Date",
                    placeholder: "dd/mm/yyyy",
                    description: "",
                },
                last_salary: {
                    label: "Last Salary",
                    placeholder: "Enter your Last Salary",
                    description: "",
                },
                current_job: {
                    label: "Current Job",
                    placeholder: "Is this your Current Job?",
                    description: "",
                },
                acting_area: {
                    label: "Segment",
                    placeholder: "Enter the Segment",
                    description: "",
                },
                company_size: {
                    label: "Company Size",
                    placeholder: "Enter the Company Size",
                    description: "",
                },
                activities: {
                    label: "Activities Performed",
                    placeholder: "Enter the Activities Performed",
                    description: "",
                },
            },
        },
        education: {
            schooling_level: {
                label: "Education Level",
                description: "",
                placeholder: "Select the Education Level",
            },
            school_file_id: {
                label: "Attach School Certificate",
                description: "",
                placeholder: "",
            },
            has_schooling_level: {
                label: "Do you have an Academic Degree?",
                placeholder: "",
                description: "",
            },
            has_not_academic_formation: {
                label: "I have no Academic Degree",
                placeholder: "",
                description: "",
            },
            has_computer_knowledge: {
                label: "Do you have Computer Knowledge?",
                placeholder: "",
                description: "",
            },
            has_languages: {
                label: "Do you speak other Languages?",
                placeholder: "",
                description: "",
            },
            has_additional_training: {
                label: "Do you have complementary courses or training?",
                placeholder: "",
                description: "",
            },
            academic: {
                education_level_id: {
                    label: "Education Level",
                    description: "",
                    placeholder: "Select the Education Level",
                },
                course_id: {
                    label: "Course",
                    description: "",
                    placeholder: "Enter the Course",
                },
                period: {
                    label: "Period",
                    description: "",
                    placeholder: "Enter the Period",
                },
                time: {
                    label: "Schedule",
                    description: "",
                    placeholder: "Enter the Time",
                },
                institution: {
                    label: "Institution",
                    description: "",
                    placeholder: "Enter the Institution",
                },
                status: {
                    label: "Status",
                    description: "",
                    placeholder: "Select the Status",
                },
                start_date: {
                    label: "Start Date",
                    placeholder: "Enter the Start Date",
                    description: "",
                },
                conclusion_date: {
                    label: "End Date",
                    placeholder: "Enter the End Date",
                    description: "",
                },
            },
            training: {
                additional_training_id: {
                    label: "Training",
                    placeholder: "Enter the Training",
                    description: "",
                },
                name: {
                    label: "Name of the course or training",
                    placeholder: "Name of the course or training",
                    description: "",
                },
                description: {
                    label: "Description of the acquired knowledge",
                    placeholder: "Description of the acquired knowledge",
                    description: "",
                },
                institution: {
                    label: "Institution",
                    placeholder: "Enter the Institution",
                    description: "",
                },
                status: {
                    label: "Status",
                    placeholder: "Select the Status",
                    description: "",
                },
                start_date: {
                    label: "Period (Start)",
                    placeholder: "Enter the Start Period",
                    description: "",
                },
                finish_date: {
                    label: "Period (Finish)",
                    placeholder: "Enter the End Period",
                    description: "",
                },
            },
            languages: {
                language_id: {
                    label: "Language",
                    placeholder: "Language",
                    description: "",
                },
                speaking: {
                    label: "Speak",
                    placeholder: "Speak",
                    description: "",
                },
                writing: {
                    label: "Writing",
                    placeholder: "Writing",
                    description: "",
                },
                understanding: {
                    label: "Understanding",
                    placeholder: "Understanding",
                    description: "",
                },
                reading: {
                    label: "Reading",
                    placeholder: "Reading",
                    description: "",
                },
            },
        },
        computing: {
            title: "Computing",
            label: "",
            basic: {
                label: "Basic",
                placeholder: "Basic",
                description: "",
            },
            intermediate: {
                label: "Intermediary",
                placeholder: "Intermediary",
                description: "",
            },
            advanced: {
                label: "Advanced",
                placeholder: "Advanced",
                description: "",
            },
        },
        additional: {
            abroad_experience: {
                label: "Experience abroad",
                placeholder: "Experience abroad",
                description: "",
            },
            has_abroad_experience: {
                label: "Has experience abroad",
                placeholder: "Has experience abroad",
                description: "",
            },
            description: {
                label: "Description",
                placeholder: "Description",
                description: "",
            },
            complementary_activities: {
                label: "Additional activities",
                placeholder: "Additional activities",
                description: "",
            },
            sports: {
                label: "sports",
                placeholder: "sports",
                description: "",
            },
            hobby: {
                label: "Hobbies",
                placeholder: "Hobbies",
                description: "",
            },
            music: {
                label: "Music",
                placeholder: "Music",
                description: "",
            },
            passions: {
                label: "Passions",
                placeholder: "Passions",
                description: "",
            },
            books: {
                label: "Books",
                placeholder: "Books",
                description: "",
            },
            social_work: {
                label: "Social work",
                placeholder: "Social work",
                description: "",
            },
            extra_informations: {
                label: "Additional Information",
                placeholder: "Additional Information",
                description: "",
            },
            cover_letter: {
                label: "Cover letter",
                placeholder: "Cover letter",
                description: "",
            },
        },
        ctps: {
            has_ctps: {
                label: "Do you have a work permit?",
                placeholder: "",
                description: "",
            },
            is_first_job: {
                label: "Will it be your first formal job?",
                placeholder: "",
                description: "",
            },
            ctps_type: {
                label: "Type of work permit?",
                placeholder: "",
                description: "",
            },
            ctps_number: {
                label: "CTPS Number",
                placeholder: "",
                description: "",
            },
            ctps_serial: {
                label: "CTPS Series",
                placeholder: "",
                description: "",
            },
            ctps_state: {
                label: "CTPS State",
                placeholder: "",
                description: "",
            },
            ctps_city: {
                label: "CTPS City",
                placeholder: "",
                description: "",
            },
            ctps_date: {
                label: "CTPS Issuance Date",
                placeholder: "",
                description: "",
            },
            ctps_file_id: {
                label: "Attach CTPS (front)",
                placeholder: "",
                description: "",
            },
            ctps_file2_id: {
                label: "Attach CTPS (Back)",
                placeholder: "",
                description: "",
            },
            ctps_file3_id: {
                label: "Attach Last CTPS Registration",
                placeholder: "",
                description: "",
            },
            has_pis: {
                label: "Do you have PIS?",
                placeholder: "",
                description: "",
            },
            pis_pasep_number: {
                label: "PIS/PASEP Number",
                placeholder: "",
                description: "",
                help: "<a href='https://selecty.movidesk.com/kb/pt-br/article/455803/8-maneiras-de-localizar-seu-pis' target='_blank'>where to find the PIS number</a>",
            },
            pis_pasep_date: {
                label: "PIS/Pasep Date",
                placeholder: "",
                description: "",
            },
            pis_file_id: {
                label: "Attach PIS",
                placeholder: "",
                description: "",
            },
        },
        voter: {
            has_voter_registration: {
                label: "Do you have a Voter ID?",
                placeholder: "",
                description: "",
            },
            voter_registration_number: {
                label: "Number",
                placeholder: "",
                description: "",
            },
            voter_registration_zone: {
                label: "Zone",
                placeholder: "",
                description: "",
            },
            voter_registration_section: {
                label: "Section",
                placeholder: "",
                description: "",
            },
            voter_registration_state: {
                label: "State",
                placeholder: "",
                description: "",
            },
            voter_registration_city: {
                label: "City",
                placeholder: "",
                description: "",
            },
            voter_registration_date: {
                label: "Date of issue",
                placeholder: "",
                description: "",
            },
            voter_registration_file_id: {
                label: "Attach Voter ID (Front)",
                placeholder: "",
                description: "",
            },
            voter_registration_file2_id: {
                label: "Attach Voter ID (Back)",
                placeholder: "",
                description: "",
            },
        },
        armed_forces: {
            has_certificate_armed_forces: {
                label: "Do you have a Reservist Certificate?",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_status: {
                label: "Situation",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service: {
                label: "Ra number",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_serie: {
                label: "Series",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_organ: {
                label: "Organ",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_uf: {
                label: "Federative unit",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_type: {
                label: "Type",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_category: {
                label: "Category",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_service_date: {
                label: "Date of issue",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_file_id: {
                label: "Attach reservist certificate (front)",
                placeholder: "",
                description: "",
            },
            certificate_of_armed_forces_file2_id: {
                label: "Attach Reservist Certificate (Back)",
                placeholder: "",
                description: "",
            },
        },
        cnh: {
            has_cnh: {
                label: "Do you have a driver's license?",
                placeholder: "",
                description: "",
            },
            cnh_number: {
                label: "Number",
                placeholder: "",
                description: "",
            },
            cnh_local: {
                label: "Place",
                placeholder: "",
                description: "",
            },
            cnh_organ: {
                label: "Issuing Agency",
                placeholder: "",
                description: "",
            },
            cnh_state: {
                label: "Emitter",
                placeholder: "",
                description: "",
            },
            cnh_type: {
                label: "CNH Type",
                placeholder: "",
                description: "",
            },
            cnh_date_emission: {
                label: "Date of issue",
                placeholder: "",
                description: "",
            },
            cnh_date_validity: {
                label: "Expiration date",
                placeholder: "",
                description: "",
            },
            cnh_date_first_emission: {
                label: "First issue",
                placeholder: "",
                description: "",
            },
            cnh_file_id: {
                label: "Attach CNH (front)",
                placeholder: "",
                description: "",
            },
            cnh_file_2_id: {
                label: "Attach CNH (Back)",
                placeholder: "",
                description: "",
            },
        },
        bank: {
            has_bank: {
                label: "Do you have a Bank Account in your name?",
                placeholder: "",
                description: "",
            },
            bank_name: {
                label: "Bank name",
                placeholder: "",
                description: "",
            },
            bank_type: {
                label: "Account Type",
                placeholder: "",
                description: "",
            },
            bank_holder_name: {
                label: "Name of Holder",
                placeholder: "",
                description: "",
            },
            bank_holder_type: {
                label: "Type of Holder",
                placeholder: "",
                description: "",
            },
            bank_agency: {
                label: "Agency",
                placeholder: "",
                description: "",
            },
            bank_account: {
                label: "Account",
                placeholder: "",
                description: "",
            },
            bank_operation: {
                label: "Bank operation",
                placeholder: "",
                description: "",
            },
            account_file_id: {
                label: "Attach Proof of Bank Account (Front)",
                placeholder: "",
                description: "",
            },
            account_file2_id: {
                label: "Attach Proof of Bank Account (back)",
                placeholder: "",
                description: "",
            },
            bank_pix_type: {
                label: "Pix Key Type",
                placeholder: "",
                description: "",
            },
            bank_pix_value: {
                label: "PIX Key",
                placeholder: "",
                description: "",
            },
        },
        dependents: {
            name: {
                label: "Full name",
                placeholder: "",
                description: "",
            },
            birth_date: {
                label: "Date of birth",
                placeholder: "",
                description: "",
            },
            gender: {
                label: "Sex", 
                placeholder: "",
                description: ""
            },
            civil_status: {
                label: "Marital status",
                placeholder: "",
                description: "",
            },
            kinship: {
                label: "Degree of kinship",
                placeholder: "",
                description: "",
            },
            rg: {
                label: "ID",
                placeholder: "",
                description: ""
            },
            cpf: { 
                label: "CPF",
                placeholder: "",
                description: ""
            },
            vaccine_number: {
                label: "Vaccination Booklet",
                placeholder: "",
                description: "",
            },
            sus_card: {
                label: "SUS Card Number",
                placeholder: "",
                description: "",
            },
            mother_name: {
                label: "Mother's name",
                placeholder: "",
                description: "",
            },
            income_tax: {
                label: "Do you want to declare it on your income tax?",
                placeholder: "",
                description: "",
            },
            life_insurance: {
                label: "Include this dependent as a beneficiary of your life insurance?",
                placeholder: "",
                description: "",
            },
            health_care: {
                label: "Include this dependent in the Health Care plan?",
                placeholder: "",
                description: "",
            },
            file_1_id: {
                label: "Attach Birth Certificate (Front)",
                placeholder: "",
                description: "",
            },
            file_2_id: {
                label: "Attach Birth Certificate (Back)",
                placeholder: "",
                description: "",
            },
            file_3_id: {
                label: "Attach CPF from the dependent",
                placeholder: "",
                description: "",
            },
            file_4_id: {
                label: "Attach School Enrollment Statement",
                placeholder: "",
                description: "",
            },
            file_5_id: {
                label: "Attach Proof of University Enrollment",
                placeholder: "",
                description: "",
            },
            file_6_id: {
                label: "Attach Declaration of Economic Dependence Registered in Notary's Office",
                placeholder: "",
                description: "",
            },
            file_7_id: {
                label: "Attach Term of Guardianship",
                placeholder: "",
                description: "",
            },
            file_8_id: {
                label: "Attach Vaccination Card (Front)",
                placeholder: "",
                description: "",
            },
            file_9_id: {
                label: "Attach SUS Card",
                placeholder: "",
                description: "",
            },
            file_10_id: {
                label: "Attach Vaccination Card (back)",
                placeholder: "",
                description: "",
            },
            file_11_id: {
                label: "Attach ID",
                placeholder: "",
                description: "",
            },
            file_12_id: {
                label: "Attach marriage certificate",
                placeholder: "",
                description: "",
            },
            file_13_id: {
                label: "Attach PCD Report",
                placeholder: "",
                description: "",
            },
            file_14_id: {
                label: "Attach Academic Transcrip",
                placeholder: "",
                description: "",
            },
        },
        epi: {
            tshirt: {
                label: "T-shirt",
                placeholder: "",
                description: "",
            },
            trousers: {
                label: "Pants",
                placeholder: "",
                description: "",
            },
            shoe: {
                label: "Shoe",
                placeholder: "",
                description: ""
            },
            jacket: {
                label: "Jacket",
                placeholder: "",
                description: "",
            },
            shirt: {
                label: "Shirt",
                placeholder: "",
                description: ""
            },
        },
        benefits: {
            benefits: {
                label: "Select the benefits you wish to receive",
                placeholder: "",
                description: "",
            },
            has_transports: {
                label: "Do you need a transportation voucher?",
                placeholder: "",
                description: "",
            },
        },
        transports: {
            way_transport: {
                label: "Route",
                description: "",
                placeholder: "",
            },
            type_transport: {
                label: "Type of Transport",
                description: "",
                placeholder: "",
            },
            path_transport: {
                label: "Journey",
                description: "",
                placeholder: "Ex: Red Line Metro (Corinthians-Itaquera)",
            },
            value_transport: {
                label: "Ticket Price (R$)",
                description: "",
                placeholder: "",
            },
        },
        cnpj: {
            has_cnpj: {
                label: "Do you have a Legal Entity?",
                placeholder: "",
                description: "",
            },
            cnpj_name: {
                label: "Corporate Name",
                placeholder: "",
                description: "",
            },
            cnpj_number: {
                label: "CNPJ",
                placeholder: "",
                description: "",
            },
        },
        contacts: {
            contact_name1: {
                label: "Name 1",
                placeholder: "",
                description: "",
            },
            contact_phone1: {
                label: "Phone 1",
                placeholder: "",
                description: "",
            },
            contact_name2: {
                label: "Name 2",
                placeholder: "",
                description: "",
            },
            contact_phone2: {
                label: "Phone 2",
                placeholder: "",
                description: "",
            },
        },
        forms: {
            transport_form_file_id: {
                label: "cAttach Transportation Allowance Card",
                placeholder: "",
                description: "",
            },
            transport_file_id: {
                label: "Copy of the Transport Allowance Card",
                placeholder: "",
                description: "",
            },
            chartered_file_id: {
                label: "Charter Form",
                placeholder: "",
                description: "",
            },
            salary_family_file_id: {
                label: "Family Salary Form",
                placeholder: "",
                description: "",
            },
            impost_file_id: {
                label: "Attach Income Tax Form",
                placeholder: "",
                description: "",
            },
            has_contribution_sindical: {
                label: "Do you want to make Union Contribution?",
                placeholder: "",
                description: "",
            },
            syndicate_contribution_file_id: {
                label: "Attach Declaration of Adherence to Union Contribution",
                placeholder: "",
                description: "",
            },
            vaccine_file_id: {
                label: "Attach Other",
                placeholder: "",
                description: "",
            },
            unemployment_insurance_file_id: {
                label: "Attach Proof of Unemployment Insurance",
                placeholder: "",
                description: "",
            },
            contribution_inss_file_id: {
                label: "Attach proof contribution INSS",
                placeholder: "",
                description: "",
            },
            other_file_id: {
                label: "Attach Other",
                placeholder: "",
                description: "",
            },
            other_file2_id: {
                label: "Attach Other",
                placeholder: "",
                description: "",
            },
            other_file3_id: {
                label: "Attach",
                placeholder: "",
                description: "",
            },
            other_file4_id: {
                label: "Attach",
                placeholder: "",
                description: "",
            },
        },
    },
    admissional: {
        fill: "Fill",
        section: 'Section',
        title: "Admission Form",
        sections: {
            personal: {
                title: "Basic Information",
                address: "Address Information",
                education: "Academic Information",
                diversity: "Diversity and Inclusion",
            },
            ctps: {
                title: "Work Permit",
            },
            voter: {
                title: "Voter ID",
            },
            armed_forces: {
                title: "Military Certificate",
            },
            cnh: {
                title: "Driver's License",
            },
            bank: {
                title: "Bank Information",
                generate_bank_letter:
                    "Generate your letter here to open a bank account",
            },
            dependents: {
                title: "Dependents",
            },
            transports: {
                title: "Transport",
            },
            epi: {
                title: "PPE/Uniform",
            },
            benefits: {
                title: "Benefits",
            },
            cnpj: {
                title: "Company Information",
            },
            contacts: {
                title: "Emergency Contacts",
            },
            forms: {
                title: "Other Documents",
            },
        },
    },
    complementary_data: {
        title: "Complementary Information",
    },
    esocial: {
        title: "E-social Registration",
    },
    admission_data: {
        title: "Admission Data",
    },
    vacancy: {
        "no-vacancy": "No vacancies to display",
        filters: {
            text: {
                label: "",
                placeholder: "Find jobs by position or company",
                description: "",

                occupation: "Position",
                interest_area: "Professional Area",
                activities: "Activities",
                requirements: "Requirements",
            },
            location: {
                label: "",
                placeholder: "Location",
                description: "",
                vacancies_at: "Vacancies at:",
            },
            interval: {
                label: "",
                placeholder: "Date posted",
                description: "",
                tokens: {
                    anyTime: "All",
                    lastDay: "Past 24 hours",
                    lastThreeDays: "Past 3 days",
                    lastWeek: "Past week",
                    lastMonth: "Past month",
                },
                action_buttons: {
                    redefine: "Redefine", 
                    see_results: "See Results"
                }
            },
            positions: {
                label: "",
                placeholder: "",
                description: "",
                tokens: {
                    all: "All",
                    oneOrMore: "More than 1 vacancy",
                    fiveOrMore: "More than 5 vacancies",
                    TenOrMore: "More than 10 vacancies",
                },
            },
            contract_type: {
                label: "",
                placeholder: "Job type",
                description: "",
                tokens: {
                    all: "All",
                    autonomous: "Self-employed",
                    clt: "CLT ( Full Time)",
                    freeLance: "Freelancer",
                    juridicPerson: "Contractor",
                    temporary: "Temporary",
                    trainee: "Trainee",
                    internship: "Internship",
                    apprentice: "Junior apprentice",
                    diarist: "Hourly worker",
                    cooperated: "Co-op",
                    outsourced: "Outsource",
                },
            },
            workplace_type: {
                label: "",
                placeholder: "Remote",
                description: "",
                tokens: {
                    all: "All job modes",
                    presential: "On-site",
                    hybrid: "Hybrid",
                    homeOffice: "Remote",
                    extern: "Outdoor",
                },
            },
            professional_area: {
                label: "",
                placeholder: "Professional area",
                description: "",
                tokens: {
                    all: "All professional areas",
                    interest_areas_1: "Commercial Administration/Sales",
                    interest_areas_2: "Business Administration",
                    interest_areas_3: "Public Administration",
                    interest_areas_4: "Law/Legal",
                    interest_areas_5: "Agriculture",
                    interest_areas_6: "Agronomy",
                    interest_areas_7: "Food and Beverages",
                    interest_areas_8: "Architecture/Urbanism",
                    interest_areas_9: "Archival Science",
                    interest_areas_10: "Performing Arts",
                    interest_areas_11: "Graphic Arts",
                    interest_areas_12: "Fine Arts",
                    interest_areas_13:
                        "Customer Service - Bars and Restaurants",
                    interest_areas_14:
                        "Customer Service - Telephonists/Receptionists",
                    interest_areas_15:
                        "Customer Service - Telemarketing/Call Center",
                    interest_areas_16: "Customer Service",
                    interest_areas_17: "Audit",
                    interest_areas_18: "Industrial Automation",
                    interest_areas_19: "Automotive",
                    interest_areas_20: "Banks",
                    interest_areas_21: "Library Science",
                    interest_areas_22: "Biology/Biological Sciences",
                    interest_areas_23: "Biomedicine",
                    interest_areas_24: "Biotechnology/Bioprocesses",
                    interest_areas_25: "Sponsorship and Development",
                    interest_areas_26: "Social Sciences",
                    interest_areas_27: "Cinema",
                    interest_areas_28: "Foreign Trade",
                    interest_areas_29: "Purchasing",
                    interest_areas_30: "Communication",
                    interest_areas_31: "Fashion Design",
                    interest_areas_32: "Civil Construction",
                    interest_areas_33: "Accounting",
                    interest_areas_34: "Controllership",
                    interest_areas_35: "Culinary/Gastronomy",
                    interest_areas_36: "Culture",
                    interest_areas_37: "Industrial Design",
                    interest_areas_38: "Business Development",
                    interest_areas_39: "Interior Design",
                    interest_areas_40: "Graphic Design",
                    interest_areas_41: "Ecology/Environment",
                    interest_areas_42: "Economics",
                    interest_areas_43: "Education",
                    interest_areas_44: "Electronics",
                    interest_areas_45: "Youth Entrepreneurship",
                    interest_areas_46: "Nursing",
                    interest_areas_47: "Occupational Health Nursing",
                    interest_areas_48: "Food Engineering",
                    interest_areas_49: "Civil Engineering",
                    interest_areas_50: "Electronic Engineering",
                    interest_areas_51: "Electrical Engineering",
                    interest_areas_52: "Mechanical Engineering",
                    interest_areas_53:
                        "Metallurgical and Materials Engineering",
                    interest_areas_54: "Mining Engineering",
                    interest_areas_55: "Production Engineering",
                    interest_areas_56: "Chemical Engineering",
                    interest_areas_57: "Other Engineering",
                    interest_areas_58: "Higher Education and Research",
                    interest_areas_59: "Education - High School/Technical",
                    interest_areas_60: "Education - Elementary School",
                    interest_areas_61: "Education - Kindergarten",
                    interest_areas_62: "Education - Distance Learning",
                    interest_areas_63: "Other Education",
                    interest_areas_64: "Entertainment",
                    interest_areas_65: "Sports",
                    interest_areas_66: "Aesthetics",
                    interest_areas_67: "Events",
                    interest_areas_68: "Pharmacy",
                    interest_areas_69: "Philosophy",
                    interest_areas_70: "Finance",
                    interest_areas_71: "Fiscal",
                    interest_areas_72: "Physics",
                    interest_areas_73: "Physical Therapy",
                    interest_areas_74: "Forestry",
                    interest_areas_75: "Speech Therapy",
                    interest_areas_76: "Geography",
                    interest_areas_77: "Geology",
                    interest_areas_78: "Business Management",
                    interest_areas_79: "Project Management",
                    interest_areas_80: "Printing and Publishing",
                    interest_areas_81: "History",
                    interest_areas_82: "Hospitality",
                    interest_areas_83: "Industrial",
                    interest_areas_84: "IT/Information Technology",
                    interest_areas_85: "Innovation",
                    interest_areas_86: "Internet",
                    interest_areas_87: "Journalism",
                    interest_areas_88: "Recreation",
                    interest_areas_89: "Languages",
                    interest_areas_90: "Logistics",
                    interest_areas_91: "Wood and Furniture",
                    interest_areas_92: "Industrial Maintenance",
                    interest_areas_93: "Building Maintenance",
                    interest_areas_94: "Marketing",
                    interest_areas_95: "Mathematics/Statistics",
                    interest_areas_96: "Mechatronics",
                    interest_areas_97: "Medicine/Hospital Care",
                    interest_areas_98: "Metallurgy",
                    interest_areas_99: "Meteorology",
                    interest_areas_100: "Educational Media",
                    interest_areas_101: "Mining",
                    interest_areas_102: "Non-metallic Minerals",
                    interest_areas_103: "Fashion",
                    interest_areas_104: "Museology",
                    interest_areas_105: "Music",
                    interest_areas_106: "Nutrition",
                    interest_areas_107: "Oceanography",
                    interest_areas_108: "Dentistry",
                    interest_areas_109: "Budget",
                    interest_areas_110:
                        "Event Organization/Cultural Production",
                    interest_areas_111: "Organization and Methods",
                    interest_areas_112: "Paper and Pulp",
                    interest_areas_113: "Heritage Management",
                    interest_areas_114: "Market Research",
                    interest_areas_115: "Oil Industry",
                    interest_areas_116: "Plastics and Polymers",
                    interest_areas_117: "Production/Manufacturing",
                    interest_areas_118: "Advertising",
                    interest_areas_119: "Psychology",
                    interest_areas_120: "Quality",
                    interest_areas_121: "Chemistry",
                    interest_areas_122: "Broadcasting and Television",
                    interest_areas_123: "Human Resources",
                    interest_areas_124: "Refrigeration and Air Conditioning",
                    interest_areas_125: "International Relations",
                    interest_areas_126: "Public Relations",
                    interest_areas_127: "Social Responsibility",
                    interest_areas_128: "Secretarial",
                    interest_areas_129: "Workplace Safety and Health",
                    interest_areas_130: "Asset Security",
                    interest_areas_131: "Insurance",
                    interest_areas_132: "Social Work",
                    interest_areas_133: "Administrative Services",
                    interest_areas_134: "Domestic Services",
                    interest_areas_135: "Specialized Services - Butchery",
                    interest_areas_136:
                        "Specialized Services - Bakery/Confectionery",
                    interest_areas_137: "Specialized Services - Fishery",
                    interest_areas_138: "General Services",
                    interest_areas_139: "Technical Services - Electrical",
                    interest_areas_140: "Technical Services - Electronics",
                    interest_areas_141: "Technical Services - Mechanical",
                    interest_areas_142: "Technical Services - Others",
                    interest_areas_143: "Supplies",
                    interest_areas_144: "Telecommunications",
                    interest_areas_145: "Occupational Therapy",
                    interest_areas_146: "Non-profit/Social Responsibility",
                    interest_areas_147: "Translation/Interpretation",
                    interest_areas_148: "Transportation - Management",
                    interest_areas_149: "Air Transport",
                    interest_areas_150: "Maritime Transport",
                    interest_areas_151: "Land Transport",
                    interest_areas_152: "Rail Transport",
                    interest_areas_153: "Training and Professional Guidance",
                    interest_areas_154: "Tax",
                    interest_areas_155: "Tourism",
                    interest_areas_156: "Sales",
                    interest_areas_157: "Sales - Retail",
                    interest_areas_158: "Technical Sales",
                    interest_areas_159: "Veterinary",
                    interest_areas_160: "Travel",
                    interest_areas_161: "Web Design",
                    interest_areas_162: "Zoology",
                    interest_areas_163: "Animal Science",
                    interest_areas_164: "Trade Marketing",
                },
            },
            results:
                "No results found | {count} job found | {count} jobs found",
            count_paginated_results: "Showing {count} of ",
            count_all_results: "{count} results",
        },
        share: {
            title: "Share",
            tw: { label: "Twitter" },
            fb: { label: "Facebook" },
            lk: { label: "LinkedIn" },
            whatsapp: { label: "WhatsApp" },
        },

        actions: {
            share: "Share",
            apply: "Apply",
            "already-applied": "Applied",
            "see-vacancy": "See job",
            seeAllVacancies: "See all",
            seeOurVacancies: "See all available positions!",
            seeOpenPositions: "See open positions",
            copy: "Copy",
            save: "Save",
            favorite: "Favorite job",
            unfavorite: "Unfavorite job",
            saved: "Saved",
            favorite_success: "Job favorited successfully!",
            please_login_to_favorite: "Please log in to favorite the job!",
            favorite_failure: "Failed to favorite the job!",
            none_favorite_vacancy: "No favorite jobs",
            almost_there: "Almost there!",
            needed_information_to_complete:
                "The information below is required to complete your application.",
            keep_looking_vacancies:
                "Check out more opportunities and add the ones that suit your profile to your favorites list.",
            save_vacancy: "Save job",
            log_to_save: "Log in to save this job",
            remove_favorite: "Remove job from favorites",
        },
        texts: {
            pcd: {
                info: "Jobs for people with disabilities",
            },
        },
        shareJob: "Share this job",
        codVacancy: "Job Code:",
        jobsQuantity: "Number of jobs:",
        contract_type: "Contract type: ",
        interest_area: "Professional area: ",
        workload: "Workload: ",
        work_schedule: "Work schedule: ",
        salary: "Salary: ",
        copyLink: "Or copy the link",
        location: "Location: ",
        company: "Company",
        pcd: "PCD Job?",
        workplace_type: "Workplace type: ",
        pcdInfo: "Types of PCD:",
        search: "Search",
        lastDay: "Last 24 hours",
        lastThreeDays: "Last 3 days",
        lastWeek: "Last week",
        lastMonth: "Last 30 days",
        anyTime: "Anytime",
        moreThan: "more than ",
        anyJobsQuantity: "Any number of jobs",
        cleanFilters: "Clear filters",
        jobs: "jobs",
        apply: "Apply",
        about: "About us",
        vacancy_info: "Job details",
        responsabilities: "Responsibilities and duties",
        requirements: "Requirements and qualifications",
        desirable: "Desirable",
        benefits: "Benefits",
        additional_info: "Additional information",
        keep_searching:
            "Keep searching and try other terms and filters to find the ideal job for you.",
        empty_vacancy: "No jobs found",
        vacancy_not_found: "Job not found",
        published_at: "Published ",
        published_days: "day | days",
        days_ago: "ago",
        published_at_x_days: " N/A|Published at {n} day ago|Published at {n} days ago",
        published_hours: "N/A|1 hour|{count} hours",
        vacancies: "vacancy | vacancies"
    },
    vacancy_by_city: "Jobs by city",
    vacancy_by_area: "Jobs by area",
    vacancy_by_occupation: "Jobs by occupation",
    home: {
        sections: {
            vacancies: {
                title: "Featured Jobs",
            },
        },
        resume: "Resume",
        "active-vacancies": "No jobs | 1 active job | {count} active jobs",
        "pending-items":
            "No pending items | 1 pending item | {count} pending items",
        "unread-messages": "1 unread | {count} unread",
        completeness: "completed",
        messages: "Messages",
        welcome_msg: "Welcome to the home page",
        hi: "Hello",
        follow: "follow",
        currentJobs: "You are applying to",
        jobs: "jobs",
        profileJobs: "Jobs that match your profile",
        appointments: "Appointments",
        curriculum: {
            empty: "Your resume is a bit empty",
            half: "You're almost done with your resume",
            full: "Your resume is complete",
            complete: "Complete your resume",
            review: "Review resume",
            follow: "Follow up",
        },
        more_than: "More than",
        job_opportunities: "job opportunities!",
        success_key: "Selecty Jobs as your key to success!",
        supportive_partner: "Here you have a partner to support you in this very important moment, the search for your next job!",
        free_for_candidates: "100% free for candidates!",
        unlimited_applications: "Unlimited applications",
        meaningful_stages: "Only meaningful interview stages",
        real_jobs: "Real jobs in real companies",
        profile_visibility: "Your professional profile viewed and consulted by thousands of companies!"
    },
    registration: {
        placeholders: {
            "email-or-cpf": "",
        },
        hello: "Hello, {0}",
        lets_go: "Let's go, {0}",
        congratulations_text: "Great that you found an opportunity!",
        account_not_found:
            "We couldn't find an account registered with this CPF, would you like to sign up?",
        candidacy_start: "Let's start your application",
        create_mini_curriculum: "Fill in your mini resume to continue",
        hasAccount: "Already have an account?",
        login: "Log in here",
        fullName: "Full name",
        typeFullName: "Enter your full name",
        createPassword: "Create a password",
        lowerLetter: "One lowercase letter",
        upperLetter: "One uppercase letter",
        number: "One number",
        specialChar: "One special character",
        eightChars: "At least 8 characters",
        agree_with: "By clicking on start, you accept",
        as: "the",
        specific_policies: "Specific Policies",
        from_company: "from the company",
        os: "the",
        terms_and_conditions: "Terms and Conditions",
        and_the: "and the",
        privacy_policy: "Privacy Policy",
        from_selecty_platform: "from the Selecty platform",
        continue: "Continue",
        start: "Get started",
        createAccount: "Accept and sign up",
        confirmPassword: "Confirm your password",
        finish_your_account_creation: "Finish creating your account",
        you_need_your_account_to_check:
            "You need it to track your applications",
        lets_create_your_password: "Let's create your password",
        you_will_need_it_to_access_your_account: "You will need it to access your account"
    },
    communication: {
        confirmation:
            "We have received your request and will review it as soon as possible.",
        error: "An error occurred while submitting your request, please try again later.",
        almost_done: "We're almost there!",
        invalid_token: {
            title: "Failure!",
            text: "Invalid token! Please try again later.",
        },
        request_sent: {
            title: "All set!",
            text: "Thank you! We have received your request and will review it as soon as possible.",
        },
        email_confirm: {
            title: "We're almost there!",
            text: "We've sent a verification link to your email to register your contact. The link is valid for 15 minutes.",
        },
    },
    confirm_communication: {
        loading: {
            title: "We are registering your contact",
            text: "Please wait...",
        },
    },
    feedback: {
        communication: {
            success: "Thank you very much! We have received your feedback.",
            confirmation: "Please confirm your email to register your contact.",
        },
        modal: {
            title: "We would like to ask for your help by telling us about your experience.",
            question_1: "From 0 to 5, how do you rate the ease of registering your data?",
            question_2: "From 0 to 5: How do you rate the clarity of the information?",
            question_3: "From 0 to 5: How do you rate the information found about your application?"
        },
        rating_0: {
            name: "rating_0",
            label: "",
        },
        rating_1: {
            name: "rating_1",
            label: "",
        },
        rating_2: {
            name: "rating_2",
            label: "",
        },
        feedback_scale_labels: {
            very_hard: "Very hard",
            hard: "Hard",
            medium: "Medium",
            easy: "Easy",
            very_easy: "Very easy"
        },
        results: {
            default: "Tell us about your experience",
            bad: "That's a shame 🙁! Tell us about your experience",
            good: "That's great 😄! Tell us about your experience"
        },
    },
    help: {
        button: {
            message: "Need Help?",
        },
        communication: {
            success:
                "We have received your query. You will be contacted as soon as possible.",
            confirmation: "Please confirm your email to register your contact.",
        },
        modal: {
            title: "Questions, Suggestions, please get in touch",
        },
        message: {
            name: "Message",
            placeholder: "Leave your message",
            label: "Tell us what happened",
            description:
                "We promise not to use your contact information to send any kind of SPAM.",
        },
        confirmation_mail: {
            title: "Thank you for confirming your email!",
        },
    },
    messages: {
        search: "Search Messages",
        noMessagesFound: "Your mailbox is empty",
        subject: "Subject:",
        noSubject: "(no subject)",
        earlierMessages: "Previous messages",
        all: "All",
        read: "Read",
        unread: "Unread",
        reply: "Reply",
        reply_message: "Type your reply message and click reply",
        edited_five_minutes: "You edited 5 minutes ago",
        edited_three_minutes: "You edited 3 minutes ago",
        message_sent_successfully: "Message sent successfully",
        message_sending_failed: "Failed to send message",
    },
    chat: {
        startConversation: "Start conversation",
        send: "Send"
    },
    config: {
        title: "Account configurations",
        shortTitle: "Account",
        notificationsTitle: "Notifications",
        linkAccount: "Link account",
        password: {
            changePassword: "Change Password",
            savePassword: "Save password",
            actualPassword: "current password",
            informActualPassword: "Enter your current password",
            newPassword: "New Password",
            informNewPassword: "Enter a new password",
            confirmPassword: "Password Confirmation",
            informConfirmPassword: "Confirm your new password",
        },
        email: {
            changeEmail: "Change your email",
            saveEmail: "Save email",
            newEmail: "New email",
            informNewEmail: "Enter the new email",
            confirmeNewEmail: "Confirm the new email",
            answer_message: "Type your response message and click on 'reply'",
        },
        notifications: {
            push: {
                title: "Push Notifications",
                description:
                    "Receive push notifications about new job openings, messages, and the progress of selection processes.",
            },
            email: {
                title: "Email Notifications",
                description:
                    "Receive updates via email about new job openings, messages, and the progress of selection processes.",
            },
            whatsapp: {
                title: "WhatsApp Notifications",
                description:
                    "Receive updates via WhatsApp about new job openings, messages, and the progress of selection processes.",
            },
        },
        dataPrivacy: {
            title: "Data Privacy",
            description1: "You can also ",
            description2: "exercise all the rights of data subjects ",
            description3:
                "provided under the General Data Protection Law – LGPD.",
            seePrivacyPolicy: "See privacy policy",
        },
        cancelAccount: {
            title: "Account Cancellation",
            btnCancelAccount: "Cancel Account",
            description1: "Account cancellation is an ",
            description2: "irreversible ",
            description3: "process that will delete your data from the system.",
            description4:
                "The cancellation and removal of all your data will be completed within 7 business days, and you will receive confirmation via email.",
        },
    },
    application: {
        status: {
            NOT_DEFINED: "Not applicable",
            STATUS_NOT_ACCEPTED: "Not selected for the Selection Process",
            STATUS_EXCLUDED: "Resume not selected for the opportunity",
            STATUS_LEAVE: "Withdrew from the Selection Process",
            STATUS_CALLED: "Participating in the Selection Process",
            STATUS_ACCEPTED: "Participating in the Selection Process",
            STATUS_WAITING: "Resume under review for the opportunity",
            STATUS_NOT_CONTACTED: "Resume under review for the opportunity",
            STATUS_THINKING: "Resume under review for the opportunity",
            STATUS_NOT_ATTEND: "Candidate did not attend the interview",
            STATUS_NO_PROFILE: "Resume not selected for the opportunity",
            STATUS_HIRED: "Approved in the Selection Process",
            STATUS_HIRING: "Approved in the Selection Process",
            STATUS_DISAPPROVED: "Not selected for the Selection Process",
            STATUS_APPROVED: "Participating in the Selection Process",
            STATUS_ACCEPTING: "Applied for the opportunity",
            STATUS_HIRED_CANCELED: "Not selected for the Selection Process",
            STATUS_HIRED_REPLACEMENT: "Not selected for the Selection Process",
        },
        applications: "Applications",
        favorites: "Favorites",
        title: "My Jobs",
        progress: "Job progress",
        jobDetail: "View job details",
        applicationStep: "You are in the application stage",
        completeSteps: "Complete the steps below to proceed",
        candidacy_complete:
            "Complete your resume to increase your chances of being found.",
        candidacy_confirmed: "Application confirmed!",
        candidacy_failure: "Pending candidacy!",
        start: "Start",
        startInfo: "To start this step, you need to complete the previous step",
        nextStep: "Next phase",
        completeMessage:
            "By completing the application track, you move to the next phase of the selection process",
        myApplications: "My Jobs",
        curriculumStep: "Resume Completion",
        quit: "Withdraw",
        seeProgress: "See progress",
        noVacancyEnrolled: "You are not participating in any selection process",
        searchForVacancies: "Search for Jobs",

        confirming_enrollment: "Confirming Application",
        we_wish_you_very_luck: "We wish you the best of luck!",
        confirmed_enrollment: "Application Confirmed",
        you_can_now_do_the_next_steps:
            "You can now proceed to the next steps in the selection process",
        see_next_steps: "See Next Steps",
        fill_my_curriculum: "Complete my resume",
        none_application_yet: "No applications yet",
        keep_searching:
            "Keep searching and adding jobs to your favorites list.",
        status_application: "Status: ",
        applied_for_job: "You applied for this position on {date}",
        application_completed_on: "Application completed on {date}",
        additional_questions: "There are additional questions to complete this application",
        completed_on: "Completed on {date}",
        awaiting_completion: "Awaiting completion of the selection process",
        not_approved: "Not approved in the selection process",
        test_completed_on: "Test completed on {date}",
        not_applied_for_job: "You are not applied for this position",
        incomplete_fields: "Not all fields have been filled. Please complete the fields below to validate your application:",
        resume_completed: "Congratulations! You have completed your resume!"
    },
    lgpd: {
        title: "Data Subject Rights Request Form",
        subtitle:
            "General Guidelines: We provide this form legally based on Article 18 of the LGPD, to facilitate the exercise of your rights under the General Data Protection Law (LGPD). Please note that the use of this form is optional, but it is an efficient and secure way to process your request. Once the form is completed, it will be forwarded, along with the applicant's identity proof(s), to the company's data protection department.",
        btn_finish: "Finish",
        declaration_of_authenticity: {
            label: "Declaration of Authenticity of the information provided",
            placeholder: "",
            description:
                "I DECLARE, under the penalties of criminal law and without prejudice to administrative and civil sanctions, that the copies of the documents attached to this document are authentic and correspond to the original document.",
        },
        data_holder: {
            label: "Are you the data subject or an attorney/representative?",
            placeholder: "",
            description: "",
        },
        sections: {
            personal_data_holder: {
                title: "Personal Data Subject Qualifications",
                subtitle:
                    "Please fill in the fields below with the qualifications of the personal data subject.",
            },
            identity_comprovation: {
                title: "Proof of Identity of the Personal Data Subject",
                subtitle:
                    "We request that a proof of identity of the personal data subject be attached to the request. Accepted civil identity documents include ID cards, passports, professional licenses, or any other documents that include the qualification and photo of the applicant. If the civil identity document is not attached to this form, Selecty may request additional information from the personal data subject to fulfill the request.",
            },
            data_holder_rights: {
                title: "Personal Data Subject Rights: Which rights would you like to exercise?",
                subtitle:
                    'Select "Yes" only for the rights you wish to exercise at this time.',
            },
            data_holder_rights_actions: {
                title: "Data Subject Rights",
                subtitle: "",
            },
            data_request: {
                title: "To assist Selecty in responding to your request quickly and efficiently, we kindly ask you to provide as much detail as possible about the information requested. Please specify your request in detail here:",
                subtitle: "",
            },
            result_request: {
                title: "Communication of the Request Result",
                subtitle: "",
            },
            legal_agent_qualifications: {
                title: "Qualifications of the Attorney or Legal Representative of the Personal Data Subject (if applicable)",
                subtitle:
                    "Please fill in the fields below with the qualifications of the attorney or legal representative of the personal data subject (if applicable).",
            },
            comprovation_power_grant: {
                title: "Proof of delegation of powers to third parties (if applicable)",
                subtitle:
                    "We request that a proof of identity of the attorney or legal representative of the personal data subject, as well as the legal instrument of representation or proof of parental representation, be attached to the request. Accepted civil identity documents include ID cards, passports, professional licenses, or any other documents that include the qualification and photo of the attorney or legal representative. If the civil identity document or the legal instrument of representation is not attached to this form, Selecty may request additional information from the applicant or the legal representative of the personal data subject to fulfill the request.",
            },
        },
        data_holder_name: {
            label: "Full name of the personal data subject",
            placeholder: "",
            description: "",
        },
        data_holder_cpf: {
            label: "CPF of the personal data subject",
            placeholder: "",
            description: "",
        },
        data_holder_phone: {
            label: "Contact phone number of the personal data subject",
            placeholder: "",
            description: "",
        },
        data_holder_email: {
            label: "Contact email of the personal data subject",
            placeholder: "",
            description: "",
        },
        data_holder_category: {
            label: "Category of the personal data subject",
            placeholder: "",
            description: "",
        },
        data_holder_additional_info: {
            label: "Additional Information",
            placeholder: "",
            description:
                "Any additional information that can help us locate your personal data",
        },
        data_holder_document: {
            label: "Attach civil identity document",
            placeholder: "",
            description: "CPF, RG, or CNH",
        },
        confirm_treatment_data: {
            label: "Do you wish to confirm the existence of processing of your personal data?",
            placeholder: "",
            description:
                "Allows you to know if Selecty has carried out any processing of your personal data, even if you are not sure whether you were attended to or had any prior relationship with our company. Art. 18, I, LGPD.",
        },
        confirm_access_data: {
            label: "Do you wish to access your data?",
            placeholder: "",
            description:
                "Allows you to learn about the personal data processing carried out by Selecty. This option should only be checked if you have already been attended to or had any prior relationship with our company. Art. 18, II, LGPD.",
        },
        hide_data: {
            label: "Do you wish to delete, anonymize, or block your data?",
            placeholder: "",
            description:
                "Allows for the anonymization, blocking, or deletion of unnecessary or excessive data, or data processed in violation of the LGPD or provided with consent. Art. 18, IV and VI, LGPD. If any personal data cannot be deleted, anonymized, or blocked due to the necessity of processing for legal obligation, contract execution, or any other reason, we will inform you.",
        },
        exercise_rights: {
            label: "If you are already aware of the existence of processing of your personal data by Selecty or if it is confirmed, which rights would you like to exercise?",
            placeholder: "",
            description: "",
        },
        data_request: {
            label: "If possible, we would like you to provide specific periods, times, dates, names, document types, or any other information regarding your request. Please remember that Selecty may contact the requester or their attorney to request additional information if the request is not sufficiently clear.",
            placeholder: "",
            description: "",
        },
        result_request: {
            label: "How would you like to be informed about the result of your request?",
            placeholder: "",
            description: "",
        },
        legal_agent_name: {
            label: "Full name of the attorney or legal representative",
            placeholder: "",
            description: "",
        },
        legal_agent_cpf: {
            label: "CPF of the attorney or legal representative",
            placeholder: "",
            description: "",
        },
        legal_agent_address: {
            label: "Address of the attorney or legal representative",
            placeholder: "",
            description: "Street, Number, Complement, ZIP code, City, State",
        },
        legal_agent_phone: {
            label: "Contact phone number of the attorney or legal representative",
            placeholder: "",
            description: "Phone with area code in the format XX XXXXX-XXXX",
        },
        legal_agent_email: {
            label: "Contact email of the attorney or legal representative",
            placeholder: "",
            description: "",
        },
        legal_agent_document: {
            label: "Proof of identity of the attorney or legal representative of the personal data subject",
            placeholder: "",
            description: "",
        },
        legal_agent_representative_document: {
            label: "Legal instrument of representation or proof of parental representation",
            placeholder: "",
            description: "",
        },
    },
    disc: {
        profile: {
            achiever: "Director",
            agent: "Representative",
            appraiser: "Evaluator",
            counselor: "Counselor",
            creative: "Creative",
            developer: "Pioneer",
            inspirational: "Inspiring",
            investigator: "Investigator",
            "objective thinker": "Objective thinker",
            overshift: "Expanded",
            perfectionist: "Perfectionist",
            persuader: "Convincing",
            pratitioner: "Practitioner",
            promoter: "Promoter",
            "result-oriented": "Results-Oriented",
            specialist: "Specialist",
            tight: "tight",
            undershift: "Compressed",
        },
        question: {
            I_am: "I am ...",
            More: "More",
            Less: "Less",
            Enthuastic: "Enthuastic",
            Daring: "Daring",
            Diplomatic: "Diplomatic",
            Satisfied: "Satisfied",
            Cautious: "Cautious",
            Determined: "Determined",
            Convicing: "Convicing",
            "Good Natured": "Good Natured",
            Friendly: "Friendly",
            Accurate: "Accurate",
            Outspoken: "Outspoken",
            Calm: "Calm",
            Talkative: "Talkative",
            Controlled: "Controlled",
            Conventional: "Conventional",
            Decisive: "Decisive",
            Adventurous: "Adventurous",
            Insightful: "Insightful",
            "Out-going": "Out-going",
            Moderate: "Moderate",
            Gentle: "Gentle",
            Persuasive: "Persuasive",
            Humble: "Humble",
            Original: "Original",
            Expressive: "Expressive",
            Conscientious: "Conscientious",
            Dominant: "Dominant",
            Responsive: "Responsive",
            Poised: "Poised",
            Observant: "Observant",
            Modest: "Modest",
            Inpatient: "Inpatient",
            Tactful: "Tactful",
            Agreeable: "Agreeable",
            Magnetic: "Magnetic",
            Insistent: "Insistent",
            Brave: "Brave",
            Inspiring: "Inspiring",
            Submissive: "Submissive",
            Timid: "Timid",
            Reserved: "Reserved",
            Obliging: "Obliging",
            "Strong-willed": "Strong-willed",
            Cheerful: "Cheerful",
            Stimulating: "Stimulating",
            Kind: "Kind",
            Perceptive: "Perceptive",
            Independent: "Independent",
            Competitive: "Competitive",
            Considerate: "Considerate",
            Joyful: "Joyful",
            Private: "Private",
            Fussy: "Fussy",
            Obedient: "Obedient",
            Firm: "Firm",
            Playful: "Playful",
            Attractive: "Attractive",
            Instropective: "Instropective",
            Stubborn: "Stubborn",
            Predictable: "Predictable",
            Logical: "Logical",
            Bold: "Bold",
            Loyal: "Loyal",
            Charming: "Charming",
            Sociable: "Sociable",
            Patient: "Patient",
            "Self-reliant": "Self-reliant",
            "Soft spoken": "Soft spoken",
            Wiling: "Wiling",
            Eager: "Eager",
            Thorough: "Thorough",
            "High-spirited": "High-spirited",
            Aggressive: "Aggressive",
            Extroverted: "Extroverted",
            Amiable: "Amiable",
            Fearful: "Fearful",
            Confident: "Confident",
            Sympathetic: "Sympathetic",
            Impartial: "Impartial",
            Assertive: "Assertive",
            "Well-desciplined": "Well-desciplined",
            Generous: "Generous",
            Animated: "Animated",
            Persistent: "Persistent",
            Impulsive: "Impulsive",
            Introverted: "Introverted",
            Forceful: "Forceful",
            "Easy-going": "Easy-going",
            "Good mixer": "Good mixer",
            Refined: "Refined",
            Vigorous: "Vigorous",
            Lenient: "Lenient",
            Captivating: "Captivating",
            Contented: "Contented",
            Demanding: "Demanding",
            Compliant: "Compliant",
            Argumentative: "Argumentative",
            Systematic: "Systematic",
            Cooperative: "Cooperative",
            "Light-hearted": "Light-hearted",
            Jovial: "Jovial",
            Precise: "Precise",
            Direct: "Direct",
            "Even-tempered": "Even-tempered",
            Restless: "Restless",
            Neighborly: "Neighborly",
            Appealing: "Appealing",
            Careful: "Careful",
            Respectful: "Respectful",
            Pioneering: "Pioneering",
            Optimistic: "Optimistic",
            Helpful: "Helpful",
        },
        form: {
            welcome: "Welcome to your assessment.",
            instructions: "Please click continue and follow the instructions.",
            hello_user: "Hello {0}",
            lets_try_again: "Start again",
            warning_recording_again:
                "The video recorded earlier for the question was not saved in the system, please try again.",
            "time-to-response": "to respond",
            minutes: "{0} min.",
            seconds: "{0} sec",
            "testing-your-camera": "Testing your camera",
            "i-can-see-me-and-the-quality-is-good":
                "I can see myself and the quality is good.",
            "testing-your-audio": "Testing your audio",
            "i-can-hear-me-and-the-quality-is-good":
                "I can hear myself and the quality is good.",
            congratulations: "Congratulations {0}",
            "you-finished": "You have completed the questionnaire",
            "wait-return": "Please wait for the recruiter to return",
            "to-start": "to start",
            "answer-now": "Answer now",
            start: "Start",
            continue: "Continue",
            "ok-already-answered": "Ready, I have already answered",
            "all-working": "All working",
            "have-a-good-interview": "Have a good interview",
            question: "Question",
            of: "of",
            disc_behavioural_profile: "DISC Behavioral Profile",
            "you-are-here-to-discover-your-behavioral-disc-profile":
                "You are here to discover your DiSC behavioral profile.",
            "what-is-it-for": "What is it for",
            "your-behavioral-profile-will-help-you-understand-how you-work-on-a-team-and-even-in-life-in-general":
                "Your behavioral profile will help you understand how you behave in a team and in life in general.",
            "lets-begin": "Let’s begin",
            "there-will-be-28-quick-questions":
                "There will be 28 quick questions",
            "see-more-instructions-next": "See more instructions below",
            "skip-and-start-the-test": "Skip and start the test",
            "youve-completed-more-than-half-of-the-test":
                "You have completed more than half of the test",
            "How_was_the_test?": "How was taking the test?",
            thanks_for_your_feedback: "Thank you for your feedback!",
            we_will_do_our_best_to_improve_your_user_experience:
                "We will do our best to improve your user experience.",
            understood: "Understood",
            start: "Start",
            disc_invalid_profile_message:
                "Based on the responses provided, it was not possible to fit your behavioral profile within the standards established by the DISC methodology.",
            remake_evaluation:
                "We need you to retake the assessment so that your profile can be identified and you can advance in this Recruitment and Selection process you applied for.",
            disc_denied_message_intro:
                "For now, it is not necessary to retake your DISC!",
            disc_denied_message_body:
                "You completed the DISC behavioral inventory in the last 6 months and your last result is still valid.",
            disc_denied_message_conclusion:
                "This measure is adopted to ensure the consistency and transparency of our process. Thank you very much for your understanding!",
            execution_date: "Performed on",
            valid_until: "Valid until",
        },
        questions: {
            self_knowledge_activity: "The following activity can help you get to know yourself even better.",
            next: "Next",
            no_right_or_wrong: "There is no right or wrong here.",
            profile_snapshot: "Upon completion, you will see a current snapshot of your profile and can use this to develop your behavioral skills.",
            instructions: "Instructions",
            choose_words: "<p>For each group of words, choose the one that <b>best fits you</b> and the one that <b>least fits you</b>.</p>",
            back: "Back",
            return: "Return",
            view_result: "View result",
            test_time_limit: "Test time limit:",
            chrome: "Chrome",
            firefox: "Firefox",
            total_test_time: "Total test time:",
            time_notice: "Once started, the timer will continue running even if you exit the test.",
        },
        results: {
            selection_process: "Selection Process",
            behavioral_profile_result: "This result presents a current portrait of your behavioral profile and does not seek to define you as an individual.",
            unique_individual_statement: "Each person is unique, and no one fits into a box.",
            information_utility_message: "We hope the information shown here can be useful to you. 😊",
            goals: "Goals",
            emotions: "Emotions",
            judges_others_based_on: "Judges others based on",
            methods_of_influencing_others: "Methods of influencing others",
            value_to_the_organization: "Value to the organization",
            uses_excessively: "Uses excessively",
            under_pressure: "Under pressure",
            avoids: "Avoids",
            needs_to_develop: "Needs to develop",
        },
        disc_card: {
            click_on_bars_to_read_description: "Click on the bars to read the description of each factor",
            high_dominance: "High Dominance",
            assertive: "Assertive",
            competitive: "Competitive",
            high_self_esteem: "High self-esteem",
            direct: "Direct",
            objective: "Objective",
            positive_behaviors: "Positive behaviors",
            adventurous: "Adventurous",
            takes_initiative: "Takes initiative",
            bold: "Bold",
            persistent: "Persistent",
            focuses_on_results: "Focuses on results",
            determined: "Determined",
            limiting_behaviors: "Limiting behaviors",
            takes_too_many_risks: "Takes too many risks",
            arrogant: "Arrogant",
            impulsive: "Impulsive",
            insensitive: "Insensitive",
            difficulty_in_delegating: "Difficulty in delegating",
            takes_on_many_responsibilities: "Takes on many responsibilities",
            brings_value_to_the_team: "Brings value to the team",
            coordinating: "Coordinating",
            innovating: "Innovating",
            with_proactivity: "With proactivity",
            with_motivation_in_the_face_of_challenges: "With motivation in the face of challenges",
            the_ideal_environment_provides: "The ideal environment provides",
            debates_to_express_viewpoints: "Debates to express viewpoints",
            freedom_to_make_decisions: "Freedom to make decisions",
            challenges_and_opportunities: "Challenges and opportunities",
            little_routine: "Little routine",
            under_pressure_can_be: "Under pressure can be",
            very_demanding: "Very demanding",
            aggressive: "Aggressive",
            selfish: "Selfish",
            low_dominance: "Low dominance",
            conservative: "Conservative",
            moderate: "Moderate",
            diplomatic: "Diplomatic",
            unpretentious: "Unpretentious",
            careful: "Careful",
            high_influence: "High influence",
            confident: "Confident",
            optimistic: "Optimistic",
            persuasive: "Persuasive",
            sociable: "Sociable",
            communicative: "Communicative",
            attentive: "Attentive",
            charming: "Charming",
            convincing: "Convincing",
            warm: "Warm",
            inspiring: "Inspiring",
            good_humored: "Good-humored",
            inattentive_to_details: "Inattentive to details",
            loses_focus_easily: "Loses focus easily",
            disorganized: "Disorganized",
            difficulty_completing_tasks: "Difficulty completing tasks",
            difficulty_managing_time: "Difficulty managing time",
            speaks_without_thinking: "Speaks without thinking",
            resolving_conflicts_creatively: "Resolving conflicts creatively",
            motivating_others: "Motivating others",
            being_optimistic: "Being optimistic",
            collaborating: "Collaborating",
            constant_contact_with_people: "Constant contact with people",
            space_to_hear_ideas: "Space to hear ideas",
            freedom_to_communicate: "Freedom to communicate",
            democratic_managers: "Democratic managers",
            very_optimistic: "Very optimistic",
            not_very_realistic: "Not very realistic",
            talks_too_much: "Talks too much",
            low_influence: "Low influence",
            serious: "Serious",
            skeptical: "Skeptical",
            reserved: "Reserved",
            reflective: "Reflective",
            rational: "Rational",
            high_stability: "High stability",
            good_listener: "Good listener",
            self_controlled: "Self-controlled",
            patient: "Patient",
            kind: "Kind",
            thoughtful: "Thoughtful",
            friendly: "Friendly",
            peacemaker: "Peacemaker",
            calm: "Calm",
            understanding: "Understanding",
            loyal: "Loyal",
            sincere: "Sincere",
            avoids_conflicts: "Avoids conflicts",
            lacks_initiative: "Lacks initiative",
            dislikes_change: "Dislikes change",
            inflexible: "Inflexible",
            difficulty_setting_priorities: "Difficulty setting priorities",
            low_ambition: "Low ambition",
            listening: "Listening",
            serving: "Serving",
            conveying_calm: "Conveying calm",
            bringing_consistency: "Bringing consistency",
            stability: "Stability",
            few_conflicts: "Few conflicts",
            lasting_work_relationships: "Lasting work relationships",
            predictability: "Predictability",
            indecisive: "Indecisive",
            inflexible: "Inflexible",
            closed: "Closed",
            low_stability: "Low stability",
            active: "Active",
            restless: "Restless",
            impetuous: "Impetuous",
            energetic: "Energetic",
            flexible: "Flexible",
            high_conformity: "High conformity",
            self_discipline: "Self-disciplined",
            precise: "Precise",
            detail_oriented: "Detail-oriented",
            prudent: "Prudent",
            perfectionist: "Perfectionist",
            analytical: "Analytical",
            diplomatic: "Diplomatic",
            seeks_achievements: "Seeks achievements",
            asks_good_questions: "Asks good questions",
            thinks_objectively: "Thinks objectively",
            low_conformity: "Low conformity",
            arrogant_when_challenged: "Arrogant when challenged",
            defensive_to_criticism: "Defensive to criticism",
            inflexible: "Inflexible",
            very_critical: "Very critical",
            slow_to_act: "Slow to act",
            seems_distant_and_cold: "Seems distant and cold",
            solves_problems_using_understanding: "Resolves problems using understanding",
            aware_and_consistent: "Conscious and consistent",
            defines_and_clarifies: "Defines and clarifies",
            gathers_information_and_tests_it: "Gathers information and puts it to the test",
            openness_to_critical_thinking: "Openness to critical thinking",
            close_relationships_with_small_groups: "Close relationships with small groups",
            privacy: "Privacy",
            freedom_to_question: "Freedom to question",
            hard_to_please: "Hard to please",
            pessimistic: "Pessimistic",
            excessively_meticulous: "Excessively meticulous",
            firm: "Firm",
            independent: "Independent",

        },
        disc_factors_bar: {
            empathetic: "Empathetic",

        }
    },
    evaluation: {
        expired: {
            title: "Deadline Expired",
            description:
                "Unfortunately, the deadline to complete the test has passed.",
        },
        form: {
            welcome: "Welcome to your assessment.",
            instructions: "Click continue to start your test.",
            attention: "Attention",
            forward_without_answering:
                "Do you want to proceed without answering?",
            not_possible_return:
                "It will not be possible to go back to this question.",
            "choose-a-quiet-and-well-lit-place":
                "Choose a quiet and well-lit place.",
            "if-possible-use-wifi-or-broadband-connection":
                "If possible, use Wi-Fi or broadband.",
            "so-you-can-focus-on-what-really-matter":
                "This way, you can focus on what really matters.",
            "this-way-you-avoid-problems-with-slowness-during-the-interview":
                "This way, you avoid problems with slowness during the interview.",
            "got-it": "Got it",
            start: "Start",
            "video-interview": {
                "fail-system-requirements":
                    "Unfortunately, your browser does not support this service.",
                welcome: "Welcome to your assessment.",
                instructions:
                    "Please click continue and follow the instructions.",
                hello_user: "Hello, {0}!",
                lets_try_again: "Start again",
                warning_recording_again:
                    "The video recorded earlier for the question was not saved in the system, please try again.",
                "time-to-response": "to respond",
                minutes: "{0} min.",
                seconds: "{0} sec",
                "testing-your-camera-and-audio":
                    "Testing your camera and microphone",
                "testing-your-camera-and-audio-description":
                    "Record a short video of yourself speaking to check your image and audio.",
                "testing-your-camera": "Testing your camera",
                "i-can-see-me-and-the-quality-is-good":
                    "I can see myself and the quality is good.",
                "testing-your-audio": "Testing your audio",
                "i-can-hear-me-and-the-quality-is-good":
                    "I can hear myself and the quality is good.",
                congratulations: "Congratulations {0}",
                "you-finished": "You have completed the questionnaire.",
                "wait-return": "Please wait for the recruiter to return.",
                "to-start": "to start",
                "answer-now": "Answer now",
                start: "Start",
                continue: "Continue",
                "ok-already-answered": "Ready, I have already answered.",
                "all-working": "All working",
                "have-a-good-interview": "Have a good interview.",
                question: "Question",
                of: "of",
            },
            "browser-not-supported": "Browser not supported",
            "please-use-one-of-the-browsers-below":
                "Please use one of the browsers below:",
        },
        evaluation_not_found: "Evaluation not found",
        successfully_reseted: "Successfully reset",
        evaluation_request_not_found: "Evaluation request not found",
    },
    "test-alerts": {
        "test-cam": "Test Camera",
        "test-cam-mic": "Test Camera and Microphone",
        "test-mic": "Test Microphone",
    },
    styleguide: {
        spinbutton: {
            field: {
                label: "Form Field Spinbutton",
                placeholder: "",
                description: "Use the fields below to adjust the component.",
            },
            step: {
                label: "Step",
                placeholder: "",
                description: "",
            },
            min: {
                label: "Min",
                placeholder: "",
                description: "",
            },
            max: {
                label: "Max",
                placeholder: "",
                description: "",
            },
        },
    },
    faq: {
        title: "Frequently Asked Questions",
        portal: {
            questions: [
                {
                    question: "Find out how to recover your login and password if you don't remember or have lost it.",
                    answer: "On the login page, click 'Forgot my password'. Then, enter your CPF and click 'Create new password'. You will receive an email registered on the platform with instructions on how to create a new password. Make sure to also check your spam or junk folder if the email is not in your inbox."
                },
                {
                    question: "Find out how to recover your login and password even without access to your email.",
                    answer: "On the login page, click 'Forgot my password', enter your CPF and click 'Create new password'. If you do not have access to the email, click 'I no longer have access to this email'. Then, provide your CPF and phone number. A confirmation code will be sent to your phone, allowing you to change your password as usual."
                },
                {
                    question: "When I register my resume, what happens to my data?",
                    answer: "When you register and do not apply for any job, your resume remains available in a resume bank and may be called upon for job opportunities. If you apply for a job, in addition to being available in a resume bank, your profile will be evaluated for the desired opportunity."
                },
                {
                    question: "I no longer want to receive emails from this company. Now what?",
                    answer: "That's a shame! Our communications are intended to help you with professional relocation and finding better opportunities. If you no longer wish to receive them, go to the email you received and click on the option 'If you no longer wish to receive messages like this, click here' or 'Unsubscribe'."
                },
                {
                    question: "How does the recruiter contact me?",
                    answer: "The company conducting your selection process has the freedom to choose the best way to contact you. If you have applied and haven't received any contact via email, phone, or social media yet, please wait for the company's response, as your profile may still be under evaluation. If you already have the recruiter's contact details, you can directly reach out to the company."
                },
                {
                    question: "How does the privacy policy work?",
                    answer: "By registering, you agree to the Privacy Policies of the hiring company (if applicable) and the Selecty platform. In the Privacy Policy, we explain in detail how we collect, use, and protect your data. We collect this information to provide the best possible service experience, personalize our support, and fulfill legal obligations, always in compliance with the LGPD (General Data Protection Law). For more information, access the link: <a href='https://selecty.com.br/politica-privacidade/' target='_blank'>Click here</a>"
                },
                {
                    question: "Can I apply for different jobs at the same company?",
                    answer: "Yes, it is possible! You can participate in multiple selection processes at the same time, but the hiring company may establish rules that define and regulate the participation of active candidates in more than one selection process simultaneously."
                },
                {
                    question: "Can I reuse my resume registration for several companies?",
                    answer: "Yes, this is possible with the login via Selecty.Jobs. You register your resume once and can reuse it for all companies with available jobs on the Selecty platform."
                },
                {
                    question: "How can I request the deletion of my data?",
                    answer: "After logging into your account, go to the 'Account' tab and scroll to the 'Data Privacy' section. Then, click on 'exercise all data subject rights' to open the Data Subject Rights Request Form. Simply complete the form and click 'Finish' to complete the request. Once this process is complete, just wait for confirmation of data deletion, which will be sent via email."
                },
                {
                    question: "How can I track my selection processes?",
                    answer: "Log in to the portal and go to the 'My Jobs' tab in the left side menu. There, you can check your status in each of the selection processes you have applied for, as well as the status of each job."
                },
                {
                    question: "Why was my application closed?",
                    answer: "Your application may have been closed for various reasons, such as the closure or cancellation of the selection process, changes in the company's selection criteria, among other factors. If you have any doubts, we recommend contacting the company directly for more details about the closure."
                },
                {
                    question: "I still haven't received feedback about my selection process.",
                    answer: "The response times may vary depending on the selection process and the company. If the informed time has already passed or you have been waiting for a prolonged period, we recommend contacting the company directly to check the status of your application. Be sure to keep an eye on your email and your profile page on the portal, where updates about the process may be sent."
                },
                {
                    question: "I have already completed the most recent stage of the selection process, but the next one hasn't been released yet.",
                    answer: "If you have completed the previous stage and the next one hasn't been released, it may be necessary to wait for the recruiter or selection team's review. To get more information about your process status, we recommend contacting the company responsible for the selection. They can provide details on the progress and if any action is needed on your part."
                },
                {
                    question: "Best practices for submitting admission documents.",
                    answer: "<p>Congratulations on your hiring! We know this is an exciting and special moment.</p><p>Now it's time to submit your documents to complete the admission process, and we would like to share some important tips to ensure success:</p><p>The allowed formats for document submission are: JPG, JPEG, GIF, PNG, WEBP, PDF, DOCX, DOC, and ODT.</p><p>If you don't have the requested document on hand, it is advisable to wait until it becomes available and avoid sending another document that does not match. For example, do not send the receipt of the CNH protocol instead of the requested original document.</p><p>Do not send expired documents. If it is a mandatory document (required to proceed with the process), contact the company responsible for the selection process to resolve the situation.</p><p>Document images must be of high quality to avoid returns and delays in the process.</p><p>Fill out all the form fields correctly and accurately. If you have doubts about what to fill in a field, do some research or consult reliable sources.</p><p>Submit all documents within the stipulated time frame and wait for the company's response. They will be organizing everything to ensure that your admission is carried out according to the law, which may take some time.</p><p>For specific questions about the job or document acceptance, please contact the recruiter or hiring company directly.</p>"
                }
            ],
        },
        sj: {
            questions: [
                {
                    question: "Discover how to recover your login and password if you don't remember or have lost them.",
                    answer: `On the login page, click on "Forgot my password". Then, enter your CPF and click on "Create new password". You will receive an email registered on the platform with instructions to create a new password. Be sure to also check your spam or junk folder if you don't find the email in your inbox.`,
                },
                {
                    question: "Discover how to recover your login and password even if you don't have access to your email.",
                    answer: `On the login page, click on "Forgot my password", enter your CPF and click on "Create new password". If you don't have access to your email, click on "I no longer have access to this email". Then, provide your CPF and phone number. A confirmation code will be sent to your phone, allowing you to change your password normally.`,
                },
                {
                    question: "What is Selecty.Jobs?",
                    answer: "For the candidate, Selecty.Jobs is a technology marketplace that connects companies and professionals. Here, you will find thousands of job opportunities and can register your resume and apply for jobs of interest for free.",
                },
                {
                    question: "What happens to my data when I register my resume?",
                    answer: "When you register and do not apply for any job, your resume becomes available to hiring companies, and you may be invited for job opportunities. If you apply for a job, in addition to making your resume available to hiring companies, your profile will be evaluated for the desired opportunity.",
                },
                {
                    question: "I no longer want to receive emails from Selecty.Jobs. What now?",
                    answer: `Oh, that's unfortunate! Our communications aim to help you in your job search and career development, but if you no longer wish to receive them, go to the received email and click on the option "If you no longer want to receive messages like this, click here" or "Unsubscribe".`,
                },
                {
                    question: "When I get a job through Selecty.Jobs, will I need to pay any fees?",
                    answer: "No. Selecty.Jobs does not charge any fees from candidates.",
                },
                {
                    question: "How does the recruiter contact me?",
                    answer: "The company conducting your selection process is free to choose the best way to contact you. If you've applied and haven't received any contact by email, phone, or social media, please wait for the company's response, as your profile might still be under review. If you already have the recruiter's contact information, you can ask the company directly.",
                },
                {
                    question: "How does the privacy policy work?",
                    answer: "In our Privacy Policy, we explain in detail how we collect, use, and protect your data. We collect this information to offer the best possible service experience, personalize our support, and comply with legal obligations, always in accordance with the LGPD (General Data Protection Law). For more information, <a href='https://selecty.com.br/politica-privacidade/' target='_blank'>Click here</a>.",
                },
                {
                    question: "Can I apply for different jobs at the same company?",
                    answer: "Yes, it's possible! You can participate in several selection processes simultaneously, but the hiring company sets the rules that limit and regulate the participation of active candidates in more than one process at the same time.",
                },
                {
                    question: "Can I reuse my resume in multiple companies?",
                    answer: "Yes. On Selecty.Jobs, you register your resume once and can reuse it with all companies that have available jobs on the platform.",
                },
                {
                    question: "How can I request the deletion of my data?",
                    answer: `After logging into your account, go to the "Account" tab and scroll down to the "Data Privacy" section, then click on "exercise all data subject rights" to open the Personal Data Subject Rights Request Form. Just complete the form and click on "Finish" to complete the request. After finishing this process, just wait for the data deletion confirmation, which will be sent via email.`,
                },
                {
                    question: "How can I track my selection processes?",
                    answer: "Log in through the <a href='https://selecty.jobs/login' target='_blank'>link</a> and go to the 'My Jobs' tab on the left sidebar. There, you can check your status in each of the selection processes you have applied for, as well as the status of each job.",
                },
                {
                    question: "Why was my application closed?",
                    answer: "Your application may have been closed for different reasons, such as the end or cancellation of the selection process, changes in the company's selection criteria, and other factors. If you have any questions, we recommend contacting the company directly for more details about the closure.",
                },
                {
                    question: "I still haven't received feedback on my selection process.",
                    answer: "The response times may vary depending on the selection process and the company. If the informed time has already passed or if you have been waiting for a long period, we recommend contacting the company directly to check the status of your application. Keep an eye on your email and your profile page on the portal, where updates on the process might be sent.",
                },
                {
                    question: "I have completed the latest stage of the selection process, but the next one has not yet been released.",
                    answer: "If you have completed the previous stage and the next one hasn't been released, it might be necessary to wait for the recruiter's or selection team's analysis. To get more information on the status of your process, we recommend contacting the company responsible for the selection. They can provide details on the progress and if any further action is required from you.",
                },
                {
                    question: "Best practices for sending admissional documents.",
                    answer: "<p>Congratulations on your hiring! We know this is an exciting and special moment.</p><p>Now, it's time to send your documents to complete the admission process, and we'd like to share some important tips to ensure the process is successful:</p><p><strong>The allowed formats for document submission are:</strong> JPG, JPEG, GIF, PNG, WEBP, PDF, DOCX, DOC, and ODT.</p><p>If you don't have the requested document on hand, it's recommended to wait until it's available and avoid sending another document that doesn't match. For example, don't send the CNH receipt protocol instead of the requested original document.</p><p>Do not send expired documents. If it's a mandatory document (necessary to advance in the process), contact the company responsible for the selection process to resolve the situation.</p><p>The document images must be of high quality to avoid rejections and delays in the process.</p><p>Fill in all the form fields correctly and accurately. If you're unsure about what to fill in a field, do some research or consult reliable sources.</p><p>Send all the documents within the stipulated deadline and wait for the company's response. They will organize everything to ensure that your admission complies with the law, which might take some time.</p><p>If you have specific questions about the job or the acceptance of other documents, contact the recruiter or hiring company directly.</p>",
                },                
                {
                    question: "How to take a good picture when sending documents to the company?",
                    answer: `<p>Congratulations on your admission! It’s time to send your documents so the process can be completed.</p>
                    <p>Taking pictures of documents seems very simple, especially with a mobile phone. Below, we've selected some tips that can make your admission process faster and more efficient when sending images for validation:</p>
                    <ul class='mx-3'>
                        <li>Find a bright, well-lit area, either naturally or with lamps, to capture your document images;</li>
                        <li>Maximize the brightness of your phone screen;</li>
                        <li>Smooth out any folds or creases, making the document as flat as possible;</li>
                        <li>Place the document on a flat, firm surface. If there’s a dark background, put it behind the document to improve the image quality;</li>
                        <li>Make sure the entire document fits within the frame, without any cropped edges;</li>
                        <li>Be mindful of shadows that might form while taking the picture;</li>
                        <li>Take the photo directly from above at the same angle as the surface (the document should form a perfect rectangle, without the top being smaller than the bottom or sides being uneven);</li>
                        <li>After capturing the image, use the app’s editing feature to crop any unwanted background around the document.</li>
                    </ul>
                    <p>Confirm the quality of the image by answering the following questions. If you answer 'no' to any of them, please retake the photo:</p>
                    <ol class='mx-3'>
                        <li>Does the entire document appear in the image? Are all sides visible without any cropping?</li>
                        <li>Is the focus adequate? Can everything be read clearly, with no blurred letters or numbers?</li>
                        <li>Is all the information on the document clearly visible? Is the brightness level correct (neither too bright nor too dark)?</li>
                    </ol>
                    <p>You can also use apps to scan the document via your phone or a printer with scanning capabilities for better quality. Remember, the system only accepts documents in the following formats: jpg, jpeg, gif, png, webp, pdf, docx, doc, odt.</p>
                    <p>Happy photo session!</p>`,
                },
                {
                    question: "How does online admission work?",
                    answer: "Online admission streamlines and speeds up the hiring process, allowing everything to be done digitally. The candidate submits their personal and professional information directly into the platform and sends the required documents to the company quickly and securely. The company may still request in-person steps, like a pre-employment medical exam, for instance.",
                },                
                {
                    question: "Which documents are required during the admission process?",
                    answer: `<p>The documents required for admission may vary depending on labor laws and the internal policies of each company, but some are generally requested in almost every admission process. In Brazil, for example, the most common documents include:</p>
                    <ul>
                        <li><strong>Work and Social Security Card (CTPS)</strong><br>Mandatory document to formalize employment.</li>
                        <li><strong>Identification Document (RG)</strong><br>Serves to verify the employee's identity.</li>
                        <li><strong>Taxpayer Registration (CPF)</strong><br>Identifies the worker to the Federal Revenue Service.</li>
                        <li><strong>Voter Registration</strong><br>An electoral compliance certificate may be required.</li>
                        <li><strong>Proof of Residence</strong><br>To verify the employee's current address.</li>
                        <li><strong>Military Service Certificate (for men)</strong><br>Proves compliance with mandatory military service, when applicable.</li>
                        <li><strong>Driver's License (CNH)</strong><br>If the job requires a driver's license, such as for drivers.</li>
                        <li><strong>Birth or Marriage Certificate</strong><br>For registering marital status.</li>
                        <li><strong>Proof of Education</strong><br>Certificates or diplomas to verify the educational level required for the job.</li>
                        <li><strong>PIS/PASEP</strong><br>If the worker is already registered, they need to provide the number.</li>
                        <li><strong>Medical Admission Certificate</strong><br>Issued after pre-employment medical exams to confirm the employee's fitness for work.</li>
                        <li><strong>3x4 Photo</strong><br>Some companies may still request a photo for personal files.</li>
                        <li><strong>Proof of CPF Registration Status</strong><br>To confirm that the CPF is in good standing with the Federal Revenue Service.</li>
                        <li><strong>Dependent Declaration for Income Tax</strong><br>Used for income tax deduction purposes and benefit calculations.</li>
                        <li><strong>Vaccination Card (for some sectors)</strong><br>Some companies require vaccination proof, especially in healthcare sectors.</li>
                    </ul>
                    <p>These are the basic documents, but some companies may request additional documents depending on the role or sector.</p>`,
                },
                {
                    question: "Emails from the platform directed to Spam or Junk Mail",
                    answer: `<p>When participating in a selection process through Selecty.Jobs, the main communication channel between the hiring company and you is usually email. Therefore, it’s important to regularly check your inbox, including the Spam folder.</p>
                    <p>Some email providers may flag messages sent by the platform as suspicious, sending them to Spam or Junk Mail folders, potentially causing you to miss important updates about the selection process.</p>
                    <p>To prevent this from happening, you can mark our emails as safe or set the @selecty.com.br domain as trusted, if your provider offers that option. Here’s how to do it:</p>
                    <p class='mb-0'>If your email provider is Google:</p>
                    <a href='https://support.google.com/mail/answer/1366858?hl=pt-PT&authuser=1&expand=5' target='_blank'>Mark or unmark emails as spam in Gmail</a>
                    <p class='mb-0 mt-3'>For Microsoft Outlook (Hotmail and Outlook):</p>
                    <a href='https://support.microsoft.com/pt-br/topic/filtrar-lixo-eletr%C3%B4nico-e-spam-no-outlook-db786e79-54e2-40cc-904f-d89d57b7f41d' target='_blank'>Filter Junk Mail and Spam in Outlook Web</a>
                    <p class='mb-0 mt-3'>For BOL and UOL domains:</p>
                    <a href='https://email.bol.uol.com.br/paginas-de-ajuda/sobre/configuracoes/antispam/como-funciona-o-antispam-do-seu-bol-mail.jhtm' target='_blank'>How BOL Mail's AntiSpam works</a><br>
                    <a href='https://email.uol.com.br/paginas-de-ajuda/sobre/configuracoes/antispam/configuracoes-antispam.jhtm' target='_blank'>How UOL's AntiSpam works</a>
                    <p class='mb-0 mt-3'>For iCloud domains:</p>
                    <a href='https://support.apple.com/pt-br/102376' target='_blank'>Mark legitimate emails as not junk</a>
                    <p class='mb-0 mt-3'>For Yahoo domains:</p>
                    <a href='https://br.ajuda.yahoo.com/kb/SLN28056.html?guccounter=2&guce_referrer=aHR0cHM6Ly9zdXBwb3J0LWNhbmRpZGF0ZXMuZ3VweS5pby8&guce_referrer_sig=AQAAAMRxkZHhZ4rJ_K3evwpoib9jAY5V1IAtFuLCfcj0BHdyL6iYyweDFc0WfvinTUyEcVYlHiahKX3z-Ka0E78bR6-thiKnX20UJ6DWYLVmfr-LKmHGdkG4T4m5NK0AUWBoAH2ru8XpLcbrR_OqNVzFvpaEwdVaMEm56S0nL3hl80b8' target='_blank'>How to manage spam and mailing lists in Yahoo Mail</a>
                    <p class='mt-3'>If your email domain is not listed above, we recommend accessing your provider's Help Center to learn how to mark emails as trusted or remove them from the Spam folder.</p>`,
                },                
                {
                    question: "Terms and Conditions of Use",
                    answer: "<a href='https://selecty.com.br/termos-de-uso/' target='_blank'>Click here</a> to learn more.",
                },
            ],
        },
    },
    terms_of_use: {
        companies: {
            title: "Terms of Use for Companies",
            subtitle: "Service: JobPost - Free Job Posting",
            content: `
                <h5>1. Company Registration, Public Profile, and Account Usage</h5>
                <p>1.1. Registering a company on the {portal_url} ("Portal") implies the obligation to provide company information and personal data of the company's representatives, including name, email, and phone number. After the registration is validated, the company will be able to access the services provided by Selecty.</p>
                <p>1.2. The JobPost service is exclusively intended for the dissemination of job vacancies from the registered company itself, acting as a "direct employer." It is not permitted to use the service to publish third-party vacancies or for any type of work that is not intended to fill the company's own workforce.</p>
                <p>1.3. Selecty reserves the right to refuse company registrations at its sole discretion, including but not limited to commercial reasons, previous violations of the Terms of Use, or reports of activities deemed inappropriate.</p>
                <p>1.4. Only duly constituted and regularly operating companies may register, with each branch maintaining its own access record. It is expressly prohibited to resell, share account access, or use the Portal for purposes other than publishing job offers.</p>
                <p>1.5. The registered company will have a public profile for disseminating information such as logos and benefits offered to employees. Registration and job posting are free for most companies; however, some may be required to purchase paid service plans to utilize certain features.</p>
                <p>1.6. Selecty may provide information about the registered company to competent authorities if legally required.</p>
                <h5>2. Job Posting Rules</h5>
                <p>2.1. Posted job vacancies must comply with current legislation and cannot contain inappropriate terms, inadequate offers, incitement to hate, discrimination, or any illicit content. The responsibility for the content of the job postings lies entirely with the publishing company, which may deactivate its offers at any time.</p>
                <p>2.2. Selecty reserves the right to block, suspend, or cancel the registration of companies that use the Portal to publish vacancies abusively or in disagreement with these Terms of Use.</p>
                <h5>3. Free Service and Fees</h5>
                <p>3.1. The JobPost service is provided free of charge, including the following functionalities: (a) posting the vacancy on the {portal_url} Portal and (b) receiving resumes from professionals who apply for the vacancy.</p>
                <p>3.2. If the company wishes to access additional resources or premium features on the platform, it may opt to subscribe to paid plans, as described in the packages offered by Selecty.</p>
                <h5>4. Vacancy Duration and Renewal</h5>
                <p>4.1. Posted vacancies will have a maximum duration of 90 (ninety) days. After this period, Selecty may, at its discretion, automatically deactivate the vacancy, allowing the company the right to renew the posting if the position has not yet been filled.</p>
                <h5>5. Job Content and Responsibility</h5>
                <p>5.1. Selecty may, at its sole discretion, audit and review the content of posted vacancies periodically; however, it does not assume any responsibility for the content published by the company. Vacancies deemed duplicate, unrealistic, or that violate current legislation will be removed from the Portal.</p>
                <h5>6. Vacancies for Minor Apprentices</h5>
                <p>6.1. The company is responsible for observing and complying with all specific rules and guidelines for hiring minor apprentices, including age and work schedule requirements.</p>
                <h5>7. Receiving Applications</h5>
                <p>7.1. Posting vacancies on the {portal_url} Portal will allow candidates to apply and make their resumes available for the company's review. From that moment, the company assumes full responsibility for communication, relationship, and negotiations with the candidate professionals.</p>
                <p>7.2. Selecty is not responsible for the information provided by professionals, nor for the compatibility of resumes with the offered vacancy, nor for the volume of applications received.</p>
                <h5>8. Data Protection</h5>
                <p>8.1. Companies commit to treating candidate data with confidentiality, using such information exclusively for selection processes related to the posted vacancy. Creating private databases or using this information for other purposes is expressly prohibited.</p>
                <p>8.2. The company expressly declares that it complies with the guidelines and principles established by the General Data Protection Law (Law No. 13.709/2018 - LGPD) and that it has an adequate structure to meet the requests of personal data holders, as well as to account for the treatments performed.</p>
                <h5>9. Complaints and Penalties</h5>
                <p>9.1. In case of irregularities or violation of these Terms of Use, Selecty may suspend or terminate the company's registration, without the right to a refund or compensation.</p>
                <h5>10. Termination and Cancellation of Services</h5>
                <p>10.1. Selecty reserves the right to cancel the provision of services at any time, without prior notice, in case of violation of these Terms of Use or any legal provision.</p>
            `,
        },
        candidates: {
            sj: {
                title: "TERMS AND CONDITIONS OF SERVICE USE",
                content: `
                    <p>The terms and conditions below apply to the benefits and services provided by the company SELECTY TECNOLOGIA PARA RH LTDA, a private legal entity, headquartered at Rua Professor Pedro Viriato Parigot de Souza, n° 3.901 – sala 38 – Cidade Industrial, in the Municipality of Curitiba, State of Paraná, CEP 81.280-330, registered with the CNPJ/MF under no. 09.236.362/0001-50, hereinafter referred to as SELECTY, for the electronic registration procedure, which may be carried out by any individual or legal entity, hereinafter referred to as USER, including the registration and maintenance of your resume in this software.</p>
                    <h5>First clause: GENERAL CONSIDERATIONS</h5>
                    <p>By accepting the terms, you agree to be bound by the following terms and conditions (TERMS AND GENERAL CONDITIONS OF USE OF THE SERVICE). If you are contracting representing a legal entity, you declare that you have the power of legal representation, as well as powers to represent all users who use the access services through your account with these terms and conditions, any natural person, legal entity (its parent company and its subsidiaries), as well as its authorized users. If you do not have such authority/powers, or if you do not agree with the terms and conditions, you must check the box “I have read and do not accept the GENERAL TERMS AND CONDITIONS OF USE OF THE SERVICE”.</p>
                    <p>By agreeing to this instrument, filling in the "I HAVE READ AND ACCEPT the GENERAL TERMS AND CONDITIONS OF USE OF THE SERVICE" after completing the registration process, the agreement with this contract is AUTOMATIC, the USER expresses his/her full consent to be able to move forward.</p>
                    <p>The USER, thus understood as a person over 18 (eighteen) years of age or a minor duly represented by his/her guardian(s) who accesses the SELECTY system in order to register and maintain his/her curriculum data in the system, declares that he/she accepts the terms and conditions contained in this term, adhering to the registration process made available by SELECTY.</p>
                    <h5>Second clause: DESCRIPTION OF PRODUCTS AND SERVICES – SUBJECT OF THE CONTRACT</h5>
                    <p>The purpose of this contract is the use by the USER of the services provided by SELECTY, consisting of:</p>
                    <p>Registration, hosting, display and dissemination of the CV registered by the USER in the SOFTWARE, which will be made available to companies interested in allocating human resources to a specific area/segment of the market; sending and receiving electronic messages (e-mail); referral of new users and the use of benefits arising therefrom. The social function of the SOFTWARE is to assist the USER in pursuing his/her objectives in the job market.</p>
                    <h5>Clause three: USER OBLIGATIONS / TERMS OF USE</h5>
                    <p>THE USER undertakes not to use the services provided by SELECTY for: Illegal purposes or to transmit or obtain material that is in violation of Brazilian law; Transmit/disclose illegal, defamatory material that violates the privacy of third parties, or that is abusive, threatening, obscene, harmful, injurious, or slanderous; Send/disclose any type of virus or files containing any type of virus or that may cause harm to the recipient or third parties; Obtain or attempt to obtain unauthorized access to other computer systems or networks connected to the Service; Create a false identity, including using, without authorization, CPF and other documents of third parties; The USER further undertakes never to enter his/her name, address, telephone number, fax number, e-mail address or any other contact information, outside of the fields specified for this purpose. If SELECTY finds that the USER has failed to comply with any of the obligations set forth in these terms of use, SELECTY reserves the right to SUSPEND or DELETE the disclosure of the USER's data, or even immediately terminate this contract, regardless of any premonitory act. The USER is solely responsible for the content of the transmissions made through the service, using his/her password or account. The USER is also responsible for the equipment necessary for his/her Internet connection (computer, telephone line, modem and computer programs for access) and for the connection costs.</p>
                    <p>The USER is prohibited from modifying, copying, distributing, transmitting, displaying, performing, reproducing, publishing, making available, licensing or creating profiles or resumes derived from third-party information that is not his/her own, under penalty of violating these terms and committing a legal infraction. The USER is prohibited from creating fake profiles.</p>
                    <p>Unless expressly authorized in writing by SELECTY to do so, the USER agrees that, when using the System/SOFTWARE, he/she will not use any trademark, service mark, registered trademark, logo of any company or organization, nor will he/she register or insert into the System/SOFTWARE any confidential information, text or image that is owned by third parties, remaining exclusively responsible and exempting SELECTY from any liability regarding the violation of intellectual property (SEE PRIVACY POLICY) of third parties that the USER may commit through such acts. In this case, SELECTY may, regardless of prior formal notification, delete any type of material filed by the USER that violates the intellectual property of third parties, including the possibility of permanently excluding the USER from the system.</p>
                    <p>Based on the principle of autonomy of will, any of the parties involved in this instrument may, at any time, terminate this instrument without reason, without this act generating the right to any type of compensation to the other party, of any nature, and in this case, they must send an email or any other form of communication to the other party, 10 (ten) days in advance, informing them of the termination, which will take effect automatically at the end of this period. Termination shall not imply waiver of any losses or damages that one party may have caused to the other during the execution of this contract.</p>
                    <p>The USER shall be solely, directly and exclusively responsible for all damages, violations, legal non-compliance and acts of any nature that may be caused to third parties or others when using the system that is the subject of this instrument. In the event that SELECTY is sued in court for damages caused by the USER, it shall be entitled to allege its passive illegitimacy, name the USER as the author, report the USER to the dispute, etc., and perform all acts necessary to exercise its right of defense and possible recourse, with this instrument serving as an extrajudicial executive title for any and all amounts eventually spent by SELECTY to cover compensation, legal expenses, etc., and these must be updated in accordance with the law, and a penalty clause of 10% (ten percent) of the total debt shall be applied.</p>
                    <p>The USER acknowledges that the content inserted into the SELECTY database, including, but not limited to, text, music, video, sounds, databases and other materials contained in other advertisements available on the service, as well as the information disclosed to the USER through the service or advertisers, may be protected by copyright, trademarks, patents or other intellectual property rights. (SEE PRIVACY POLICY) The USER is aware that he/she may use the information and materials only if authorized by SELECTY or by the advertisers. The USER may not copy, reproduce, transmit, distribute or recreate in a derivative manner the content already provided, without the express authorization of the owner of the property right.</p>
                    <p>Violation of any of the terms below will result in the cancellation of your account. The USER is aware that SELECTY cannot be held responsible for the content posted on the SOFTWARE and you may be exposed to such materials. USER agrees to use the SOFTWARE at his/her own risk.</p>
                    <h5>Clause four: SELECTY'S RESPONSIBILITIES</h5>
                    <p>For all legal purposes, SELECTY clarifies and informs the USER that all of their curriculum data and personal information, entered through access to the SELECTY website, will be consulted at any time by other companies that use the computerized systems made available by SELECTY TECNOLOGIA PARA RH LTDA, which function as a basis for research and screening of candidates and selection processes. In this sense, the data will be archived in the systems made available by the latter, which may disclose them to other companies. SELECTY hereby informs that it will disclose the USER's personal data to the State and Public Authorities without the USER's prior permission only when legally required to do so.</p>
                    <p>SELECTY may, at any time, amend the terms of this contract in the event of a need for adjustment, updating or altering them, committing to make a new copy available to its users, informing the SOFTWARE of the existence of the new version of the contract and requesting express consent.</p>
                    <p>SELECTY will seek to keep the system or SOFTWARE accessible uninterruptedly every day (24 hours x 7 days a week) free from system errors. The USER is aware that SELECTY cannot be held responsible for the impossibility of submitting data to the system due to the impossibility of access or communication failure of the SOFTWARE system or for other reasons beyond its control.</p>
                    <p>SELECTY is not responsible for the USER's placement or replacement in the job market, nor for the consequences arising from the USER's position in the job market that occurred through the SOFTWARE.</p>
                    <p>SELECTY is not responsible for any material or moral damages, among others, to the USER's professional life caused by the viewing of his/her resume in the SOFTWARE by third parties in general.</p>
                    <p>SELECTY is not responsible for media, advertisements, products, content offered in the SOFTWARE through links, cookies with advertisements. It is also not responsible for any damages eventually suffered by the USER due to access to the resume by third parties. Furthermore, SELECTY is not responsible for the content or veracity of job advertisements published in the SOFTWARE. Therefore, any negotiation between the USER and the third party advertiser will not be the responsibility of SELECTY, compromising only the third party advertiser and the respective USER who hired the third party advertiser.</p>
                    <p>SELECTY will not be responsible for any damages resulting from illicit access by third parties or hackers to the SOFTWARE. SELECTY is not responsible for any damages that may occur to the USER's equipment or third parties who access the SOFTWARE, caused by misuse of the SOFTWARE and other software, possible hardware or use of the internet.</p>
                    <p>SELECTY has constantly implemented security measures designed to protect the personal information that the USER shares, including physical, electronic and procedural measures. SELECTY also regularly monitors its systems for possible vulnerabilities and attacks, and regularly seeks new ways and third-party services to improve the USER's security and privacy.</p>
                    <p>SELECTY is authorized to consult the information entered by the USER in order to identify and solve technical problems, provide support to the USER, comply with legal orders and whatever else is necessary to ensure that the services are provided within the ethical and legal precepts in force and applicable to the type.</p>
                    <p>Regardless of the measures and efforts made by SELECTY, it is not possible and is not guaranteed the absolute protection of the USER's personal information, or any other material that the USER uploads, publishes or otherwise shares with SELECTY. For this reason, SELECTY encourages the USER to use strong passwords on their account and to avoid providing SELECTY or any other person with any sensitive information that the USER believes could cause considerable and irreparable damage if revealed.</p>
                    <p>In cases where unauthorized third parties invade the system illegally, SELECTY is not responsible for the damages caused by them.</p>
                    <h5>Fifth Clause: REGISTRATION AND EDITING OF THE CV</h5>
                    <p>The USER will register to use the services covered by this contract by creating a unique identification and password and correctly filling in personal and professional information, for the automatic creation of his/her professional CV.</p>
                    <p>The USER is obliged to provide true, updated and complete information, observing the requests on the registration pages. Among this information, you will be asked to provide your own CPF/MF (INDIVIDUAL ENTITY REGISTRATION – MINISTRY OF FINANCE) or, if you do not have one, the CPF of your legal guardian.</p>
                    <p>The insertion, correction, as well as the veracity of all data and information provided by the USER when registering your resume, or in any other service, will be the sole and exclusive responsibility of the USER.</p>
                    <p>The USER is obliged to keep his/her password confidential and must take all necessary precautions to prevent its incorrect use by unauthorized third parties, and will also be liable for any improper use thereof. SELECTY is not responsible for unauthorized access to the USER's password.</p>
                    <p>The USER is prohibited from creating and maintaining more than one registration in the software.</p>
                    <p>After registering, the USER may, at any time, edit any data contained in his/her CV, with the exception of his/her CPF/MF number, by simply LOGING IN to the SOFTWARE.</p>
                    <p>Once the USER has LOGED IN to the SOFTWARE, he/she may insert additional data to his/her CV (such as professional experience, languages, courses, qualifications, telephone numbers), choose to make his/her professional CV confidential, modify and update any and all information contained in his/her database, make his/her CV available for access and viewing by other companies, and choose to receive emails or messages on his/her cell phone.</p>
                    <p>The USER is obliged to update his/her information in the system within the legal deadline. SELECTY is permitted and authorized to delete the records of USERS who do not update their data within this period, regardless of any prior warning.</p>
                    <p>All obligations related to Registration also apply to the editing of the resume by the USER.</p>
                    <p>The USER is hereby informed that through the configuration key made available in the SELECTY SOFTWARE, the USER may deactivate his/her resume, thus making it unavailable for search access by interested companies that use the SELECTY system.</p>
                    <h5>Clause six: VALUE OF SERVICES</h5>
                    <p>For the registration and dissemination of his/her resume, the USER will not pay any amount to SELECTY, since registration is completely free. SELECTY is not responsible for any payments made by the USER to third parties, as it hereby informs that it does not charge the USER any amount for using the SOFTWARE.</p>
                    <h5>Clause seven: DURATION OF THE CONTRACT</h5>
                    <p>This contract is for an indefinite period and remains valid as long as the USER keeps his/her resume registered in the SOFTWARE, duly updated.</p>
                    <p>The USER may, at any time, terminate this contract, by simply contacting the SELECTY SOFTWARE and providing his/her CPF number requesting the deletion of his/her resume in the SOFTWARE, being aware that the cancellation of his/her registration will no longer be disclosed.</p>
                    <h5>Clause eight: JURISDICTION</h5>
                    <p>The terms of the services and the relationship between the parties will be governed by and interpreted in accordance with the Laws of the Federative Republic of Brazil. The USER and SELECTY expressly agree to submit to the sole and exclusive jurisdiction of the Brazilian courts. Any disputes or litigation involving this agreement will be submitted for processing and judgment before the courts of the district of Curitiba/PR, waiving any other, however privileged it may be.</p>
                    <h5>Clause nine: GENERAL PROVISIONS</h5>
                    <p>All and any communications between the parties must be made by e-mail, correspondence or any written means that can prove receipt by the other party.</p>
                    <p>Again, the USER expressly authorizes SELECTY to publish his/her resume on other Internet sites, using computerized systems made available by SELECTY TECNOLOGIA PARA RH LTDA, in which case all the terms of this agreement will apply, which will be automatically transferred to other companies that make the information available.</p>
                    <p>Therefore, by accepting the terms, you agree to be bound by the following terms and conditions (GENERAL TERMS AND CONDITIONS OF USE OF THE SERVICE) as mentioned above.</p>
                `
            }
        }
    },
    privacy_policy: {
        sj: {
            title: "About our Privacy Policy:",
            subtitle: "How we respect your data",
            content: `
                <h5>Why do we collect your data?</h5>
                <p>We work to deliver the best services and support to you, always offering a good and exclusive experience! That's why we need to collect some of your data.</p>
                <p>We use this data so that our communication with you is as assertive as possible. This way, we can serve you in a personalized way.</p>
                <p>In addition, we need this data to fulfill our legal and regulatory obligations so that everything is in compliance with the LGPD (General Data Protection Law).</p>
                <h5>How do we use your data?</h5>
                <p>We can act in three ways: as Controller, Co-controller or even as Personal Data Operator.</p>
                <p>But what does this mean? Check the description of each classification: </p>
                <ol class="px-5">
                <li><b>CONTROLLER:</b> We use the data to formalize contracts with clients, recruitment and selection, admission, research, lead qualification, marketing actions, research and updating of lead information;</li>
                <li><b>CO-CONTROLLER:</b> The data is used to register employees in health and dental plans, payroll and others;</li>
                <li><b>OPERATOR:</b> Activities related to the SELECTY platform, especially the processing, storage, access and deletion of personal data.</li>
                </ol>
                <h5>Who do we share with?</h5>
                <p>Internally, the data we collect is accessed only by authorized professionals, always thinking about the security of your data and better service to you.</p>
                <p>We may share this data with our partners, who follow security and confidentiality standards. We always carefully evaluate our suppliers and enter into contractual obligations with them regarding information security and personal data protection, with the aim of minimizing risks.</p>
                <p>Some public authorities (such as Regulatory Authorities, Federal Revenue, Ministries and other national authorities) may have access to data in order to comply with legal or regulatory obligations</p>
                <h5>How do we protect your data?</h5>
                <p>Our measures to protect data against unauthorized access, use, alteration, disclosure or destruction are:</p>
                <ul class="px-5">
                <li>Physical and digital protection against access attempts by unknown people or programs;</li>
                <li>Management of who can access your data;</li>
                <li>Internal security software and policies.</li>
                </ul>
                <p>All of these controls are always reviewed to keep up with the context of threats on the internet and we have teams prepared to detect and respond promptly in the event of an event or incident that compromise the security of your data or our services.</p>
            `
        }
    },
    footer: {
        sj: {
            fastest_way_to_hire: "The fastest way to hire and get hired! ;)",
            office_location: "Office Life Ecoville",
            office_address: "Professor Pedro Viriato Parigot de Souza, 3901",
            welcome: "We are in Curitiba ♥ Cradle of innovation",
            for_companies: "For Companies",
            post_your_jobs: "Post your jobs",
            full_recruitment_management: "Full Recruitment Management",
            outsource_your_job: "Outsource your job",
            for_candidates: "For Candidates",
            search_jobs: "Search Jobs",
            create_profile: "Create profile",
            privacy_policy: "Privacy Policy",
            faq: "FAQ",
            follow_us: "Follow us on social media!",
            copyright: "Copyright © {year} | Selecty.Jobs © All rights reserved",
            company: "Selecty Tecnologia para RH | CNPJ: 09.236.362/0001-50"
        }
    },
    domains: {
        logo_position: {
            normal: "Normal",
            centralized: "Centralized",
            top_square: "Top square",
        },
        gender: {
            male_abb: "Masc",
            female_abb: "Fem",
            do_not_inform: "Don't inform",
        },
        nationalities: {
            brazilian_native: "Brazilian native",
            naturalized: "Naturalized",
            foreigner: "Foreigner",
        },
        data_holder: {
            I_am_data_holder: "I am the data owner",
            I_am_attorney_or_representative: "I am an attorney or representative",
        },
        data_holder_category: {
            client: "Customer",
            collaborator: "Collaborator",
            lead_prospect: "Lead/Prospect",
            former_collaborator: "Former collaborator",
            supplier: "Supplier",
        },
        exercise_rights: {
            correct_data_incomplete_inaccurate_outdated: "Correct incomplete, inaccurate, or outdated data",
            allow_data_portability: "Allow data portability to another service or product provider",
            receive_information_shared_entities: "Receive information from public and private entities with whom the controller shared data",
            revoke_consent: "Revoke consent",
            review_automated_decisions: "Review decisions made solely based on automated processing of personal data",
            confirm_data_processing_and_access: "Only confirm the existence of processing and access my data being processed by Selecty"
        },
        result_request: {
            electronic_contact_email: "Electronic means (email): the contact email will be the same provided for the personal data holder or attorney, when applicable",
            printed_format_address: "Printed format: the address used will be the same provided for the personal data holder or attorney, when applicable"
        },
        armed_forces_service_status: {
            armed_forces_enlisted: "Enlisted",
            armed_forces_reservist: "Reservist",
            armed_forces_exempt:  "Exempt",
        },
        bank_types: {
            bank_type_current_account: "Current Account",
            bank_type_savings_account: "Savings Account",
            bank_type_salary_account: "Salary Account",
        },
        bank_holder_types: {
            bank_holder_individual: "Individual",
            bank_holder_legal_entity: "Legal Entity",
        },
        bank_pix_types: {
            pix_type_not_informed: "Not Informed",
            pix_type_phone: "Phone",
            pix_type_email: "Email",
            pix_type_cpf_cnpj: "CPF/CNPJ",
            pix_type_random_key: "Random Key",
        },
        tshirt: {
            tshirt_size_small: "Small",
            tshirt_size_medium: "Medium",
            tshirt_size_large: "Large",
            tshirt_size_extra_large: "Extra Large",
            tshirt_size_extra_extra_large: "Extra Extra Large",
        },
        benefits: {
            benefit_meal_voucher: "Meal Voucher",
            benefit_food_voucher: "Food Voucher",
            benefit_medical_assistance: "Medical Assistance <small>(check if your company is eligible for this benefit)</small>",
            benefit_transport_voucher: "Transport Voucher",
        },
        way_transports: {
            go : "Go",
            back : "Back",
        },
        transports: {
            bus: "Bus",
            subway: "Subway",
            train: "Train",
            van: "Van",
            integration: "Integration",
        },
        vacancies: {
            vacancy_removed_from_favorites_success: "Vacancy successfully removed from your favorites list",
        },
        account: {
            registration_found: 'We found your registration!',
            registration_found_provide_password: "We found your registration, please provide your password!",
            user_not_registered_employee_portal_exclusive: "We did not find your registration as an employee. This portal is exclusive to employees!",
        },
        civil_status: {
            not_informed: "Not informed",
            single: 'Single',
            married: 'Married',
            separated: "Separeted",
            divorced: "Divorced",
            widowed: "Widowed",
            civil_union: "Civil Union",
        },
        document_type: {
            national_registry_of_foreigners: "National Registry of Foreigners",
            passport: "Passport",
            national_migration_registry: "National Migration Registry",
        },
        company_sizes: {
            large_company: "Large Company",
            medium_company: "Medium Company",
            small_company: "Small Company",
            micro_company: "Micro Company",
            self_employed_activity: "Self-Employed Activity",
        },
        acting_areas: {
            agriculture_livestock: "Agriculture and Livestock",
            food: "Food",
            architecture: "Architecture",
            arts: "Arts",
            medical_assistance: "Medical Assistance",
            associations: "Associations",
            audit: "Audit",
            automotive: "Automotive",
            auto_parts: "Auto Parts",
            aviation: "Aviation",
            banks: "Banks",
            beverages: "Beverages",
            biological_sciences: "Biological Sciences",
            gifts: "Gifts",
            toys: "Toys",
            footwear_leather: "Footwear and Leather",
            bedding_bath: "Bedding, Bath, and Table",
            wholesale_trade: "Wholesale Trade",
            foreign_trade: "Foreign Trade",
            retail_trade: "Retail Trade",
            visual_communication_design: "Visual Communication and Design",
            civil_construction: "Civil Construction",
            consulting: "Consulting",
            accounting: "Accounting",
            brokerage: "Brokerage",
            dealership: "Dealership",
            distributors: "Distributors",
            education: "Education",
            appliances: "Appliances",
            packaging: "Packaging",
            energy: "Energy",
            engineering: "Engineering",
            teaching_research: "Teaching and Research",
            entertainment_culture_leisure: "Entertainment – Culture and Leisure",
            sports: "Sports",
            beauty_aesthetics: "Aesthetics and Beauty",
            pharmaceutical: "Pharmaceutical",
            tools: "Tools",
            finance: "Finance",
            franchises: "Franchises",
            tobacco: "Tobacco",
            government: "Government",
            printing: "Printing",
            hygiene_cleaning: "Hygiene and Cleaning",
            hospitality: "Hospitality",
            real_estate: "Real Estate",
            press_communication: "Press and Communication",
            industry: "Industry",
            internet: "Internet",
            legal: "Legal",
            wood: "Wood",
            machinery_equipment: "Machinery and Equipment",
            construction_material: "Construction Material",
            office_supplies: "Office Supplies",
            electronic_material: "Electronic Material (Electronic Components)",
            mechanics: "Mechanics",
            environment: "Environment",
            metallurgy: "Metallurgy",
            mining: "Mining",
            furniture_decor: "Furniture and Decoration",
            non_metals: "Non-Metals",
            paper_pulp: "Paper and Pulp",
            perfumery_cosmetics: "Perfumery and Cosmetics",
            petrochemical: "Petrochemical",
            plastic_rubber: "Plastic and Rubber",
            advertising: "Advertising",
            chemistry: "Chemistry",
            human_resources: "Human Resources",
            religion: "Religion",
            health_hospital_lab: "Health, Hospital, and Laboratory",
            insurance_private_pension: "Insurers and Private Pension",
            private_security: "Private Security",
            other_services: "Other Services",
            public_services: "Public Services",
            technology_it: "Technology and IT",
            telecommunications: "Telecommunications",
            telemarketing: "Telemarketing / Call Center",
            third_sector_ngo: "Third Sector / NGO",
            textile: "Textile",
            domestic_work: "Domestic Work",
            air_transport: "Air Transport",
            transport_logistics: "Transport and Logistics",
            tourism: "Tourism",
            housewares: "Housewares",
            clothing: "Clothing",
            veterinary: "Veterinary",
        },
        admissional_education_level: {
            illiterate: "Illiterate",
            up_to_5th_grade_elementary: "Up to 5th Grade of Elementary School",
            completed_5th_grade_elementary: "Completed 5th Grade of Elementary School",
            from_6th_to_9th_grade_elementary: "From 6th to 9th Grade of Elementary School",
            completed_elementary: "Completed Elementary School",
            incomplete_high_school: "Incomplete High School",
            completed_high_school: "Completed High School",
            incomplete_higher_education: "Incomplete Higher Education",
            completed_higher_education: "Completed Higher Education",
            incomplete_postgraduate: "Incomplete Postgraduate",
            completed_postgraduate: "Completed Postgraduate",
            incomplete_master: "Incomplete Master's Degree",
            completed_master: "Completed Master's Degree",
            incomplete_doctorate: "Incomplete Doctorate",
            completed_doctorate: "Completed Doctorate",
            incomplete_postdoctorate: "Incomplete Postdoctoral Degree"
        },
        cnh_type: {
            a: "A",
            b: "B",
            c: "C",
            d: "D",
            e: "E",
            ab: "AB",
            ac: "AC",
            ad: "AD",
            ae: "AE",
            no_license: "No driver's license"
        },
        street_type: {
            airport: "Airport",
            alley: "Alameda",
            area: "Area",
            avenue: "Avenue",
            field: "Field",
            farm: "Farm",
            colony: "Colony",
            condominium: "Condominium",
            complex: "Complex",
            district: "District",
            esplanade: "Esplanade",
            station: "Station",
            road: "Road",
            shantytown: "Shantytown",
            market: "Market",
            garden: "Garden",
            hill: "Hill",
            lake: "Lake",
            lagoon: "Lagoon",
            square: "Square",
            allotment: "Allotment",
            hillock: "Hillock",
            core: "Core",
            park: "Park",
            catwalk: "Catwalk",
            courtyard: "Courtyard",
            plaza: "Plaza",
            block: "Block",
            retreat: "Retreat",
            residential: "Residential",
            highway: "Highway",
            street: "Street",
            sector: "Sector",
            ranch: "Ranch",
            lane: "Lane",
            section: "Section",
            cloverleaf: "Cloverleaf",
            valley: "Valley",
            path: "Path",
            way: "Way",
            viaduct: "Viaduct",
            alleyway: "Alleyway",
            village: "Village"
        },
        race: {
            no_information: "Prefer not to disclose",
            white: "White",
            black: "Black",
            brown: "Brown",
            yellow: "Asian",
            indigenous: "Indigenous"
        },
        gender_identity: {
            cis_woman: "Cis Woman",
            trans_woman: "Trans Woman",
            cis_man: "Cis Man",
            trans_man: "Trans Man",
            non_binary: "Non-binary",
            other: "Other",
            prefer_not_to_answer: "Prefer not to answer"
        },
        gender_orientation: {
            asexual: "Asexual",
            bisexual: "Bisexual",
            heterosexual: "Heterosexual",
            homosexual: "Homosexual",
            pansexual: "Pansexual",
            other: "Other",
            prefer_not_to_answer: "Prefer not to answer"
        }
    },
};
